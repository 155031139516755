import { useEffect, useCallback, useContext } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button, ButtonGroup, darken, Divider, Drawer, IconButton, makeStyles, ThemeProvider, useTheme } from "@material-ui/core";
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from "@material-ui/icons";
import { ProjectList, ProjectSearch } from "components/project";
import { drawerOpenState, drawerWidthState } from "states/layout";
import MapContext from "Map/MapContext";
import { ConfigContext } from "config";
import { useRouter } from "hooks";

const useStyles = (drawerWidth: number) =>
    makeStyles((theme) => ({
        drawer: {
            width: drawerWidth,
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column" /*수직 정렬*/,
            alignItems: "center",
            justifyContent: "center",
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: theme.palette.type === "dark" ? "#666" : "#eee"
        },
        drawerHeader: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: "flex-end"
        },
        btnCreateGrp: {
            padding: theme.spacing(1, 1, 0),
            margin: theme.spacing(0)
        }
    }));

const Left = () => {
    const { history } = useRouter();
    const config = useContext<any>(ConfigContext);
    const drawerWidth = useRecoilValue<number>(drawerWidthState);
    const classes = useStyles(drawerWidth)();
    const theme = useTheme();
    const { innoMap } = useContext(MapContext);
    const [open, setOpen] = useRecoilState<boolean>(drawerOpenState);

    const handleDrawerToggle = useCallback(() => {
        setOpen(!open);
    }, [setOpen, open]);

    useEffect(() => {
        if (innoMap) {
            innoMap.updateSize();
        }
    }, [innoMap, open]);

    const handleClickDialogOpen = () => {
        history.push("/project/register");
    };

    return (
        <ThemeProvider theme={config.theme.navbarTheme}>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerToggle}>{theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
                </div>
                <Divider />
                <ButtonGroup className={classes.btnCreateGrp} fullWidth={true}>
                    <Button variant="contained" color="primary" onClick={handleClickDialogOpen}>
                        프로젝트 생성
                    </Button>
                    {/* <Button variant="contained" color="primary">
                        통계
                    </Button> */}
                </ButtonGroup>

                <ProjectSearch />

                <ProjectList />
            </Drawer>
        </ThemeProvider>
    );
};

export default Left;
