import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import img_map01 from './images/img_map01.jpg'
import img_map02 from './images/img_map02.jpg'
import img_map03 from './images/img_map03.jpg'

const styles = makeStyles((theme) => ({
    background: {
        position: 'absolute',
        zIndex: 100,
    },
    mapImg: {
        float: 'left',
        marginLeft: '2px',
        backgroundColor: '#fff',
        border: '1px solid #5e6368',
        borderRadius: '3px',
        overflow: 'hidden',
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            borderColor: '#ed1c24'
        }
    },
    mapText: {
        fontSize: '11px',
        color: '#444',
        textAlign: 'center',
        lineHeight: '20px'
    },
    mapImgHighlight: {
        borderColor: '#ed1c24'
    }
}));

const backgroundType = [
    {
        mapType: 'DawulBase',
        img: img_map01,
        mapText: '일반지도'
    },
    {
        mapType: 'DawulSatellite',
        img: img_map02,
        mapText: '위성지도'
    },
    {
        mapType: 'DawulHybrid',
        img: img_map03,
        mapText: '하이브리드'
    },
];

const MapBackground = ({ backgroundMap }: any): React.ReactElement => {
    const classes = styles();

    const [mapType, setMapType] = useState('');

    const handleClickBackground = (kind: string) => (evt: React.MouseEvent) => {
        if (!backgroundMap) return;
        setMapType(kind);
        backgroundMap.changeBackground(kind);
    };

    return (
        <ul className={classes.background}>
            {backgroundType.map((background, idx) => (
                <li key={background.mapType} className={clsx(classes.mapImg, ((mapType === '' && idx === 0) || (mapType === background.mapType)) && classes.mapImgHighlight)} onClick={handleClickBackground(background.mapType)}>
                    <img src={background.img} alt={background.mapText}/>
                    <div className={classes.mapText}>{background.mapText}</div>
                </li>
            ))}
        </ul>
    );
}

export default MapBackground;