import { Route, Switch } from "react-router-dom";
// import logo from './logo.svg';
// import './App.css';
import MapPage from "pages/map/MapPage";
import { NotFound } from "pages/error";
import InnoConfig from "config/InnoConfig";
//Login Page 추가
import LoginPage from "pages/login/LoginPage";

function App() {
    return (
        <InnoConfig>
            <Switch>                
                <Route exact path="/login" component={LoginPage}/>
                <Route exact path="/" component={MapPage} />
                <Route exact path="/project/register" component={MapPage} />
                <Route exact path="/project/result/:projectId" component={MapPage} />
                <Route component={NotFound} />
            </Switch>
        </InnoConfig>
    );
}

export default App;
