import React from "react";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog } from "@material-ui/core";

export interface InnoConfirmDialogProp {
    open: boolean;
    title: string;
    content?: string;
    handleCancel?: any;
    handleOk: any;
}

const InnoConfirmDialog = ({ open, title, content, handleCancel, handleOk }: InnoConfirmDialogProp): React.ReactElement => {
    return (
        <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            {content && (
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                {handleCancel && (
                    <Button onClick={handleCancel({ open, title, content })} color="inherit">
                        취소
                    </Button>
                )}
                <Button onClick={handleOk({ open, title, content })} color="primary" autoFocus>
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
};

InnoConfirmDialog.defaultProps = {
    open: false,
    title: "",
    content: "",
    handleCancel: () => {},
    handleOk: () => {}
};

export default InnoConfirmDialog;
