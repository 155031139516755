import React from "react";
import { createMuiTheme, Theme } from "@material-ui/core";
import { useRecoilState } from "recoil";

import { settingsConfig, themesConfig, ConfigContext } from ".";
import { ConfigContextInterface } from "./ConfigContext";
import { currentThemeState } from "states/common";

const InnoConfig: React.FC<{}> = ({ children }) => {
    const [currentTheme] = useRecoilState(currentThemeState);
    const { main, navbar, toolbar } = (settingsConfig.themeset.find((e) => e.name === currentTheme) || settingsConfig.themeset[0]).theme;

    const mainTheme: Theme = createMuiTheme(themesConfig[main]);
    const navbarTheme = createMuiTheme(themesConfig[navbar]);
    const toolbarTheme = createMuiTheme(themesConfig[toolbar]);

    const config: ConfigContextInterface = {
        theme: { mainTheme, navbarTheme, toolbarTheme }
    };

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};
export default InnoConfig;
