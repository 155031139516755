import { Feature, Map, Overlay, View } from "ol";
import { Layer, Vector as VectorLayer } from "ol/layer";
import { defaults } from "ol/control";
import {
    fromLonLat,
    get as getProjection,
    transform,
    transformExtent,
    ProjectionLike
} from "ol/proj";
import { Extent } from 'ol/extent';
import { GeoJSON, WKT } from 'ol/format';

import 'ol/ol.css';

class InnoMap { 
    map: Map;
    mapId: string;
    private _backgroundInfo: any;
    private _options: any;

    /**
     * 생성자
     * @param mapId {string}
     * @param options {{}}
     * @param backgroundInfo
     */
    constructor(mapId: string, options: any, backgroundInfo: any) {
        // this.map = null;
        this.mapId = mapId;
        this._backgroundInfo = backgroundInfo;
        this._options = Object.assign({
            center : fromLonLat([126.978509, 37.566611], getProjection(this._backgroundInfo.crsCode)),
            // center : fromLonLat([127.0, 37.6], getProjection(this._backgroundInfo.crsCode)),
            // center : fromLonLat([126.97, 37.52], getProjection(this._backgroundInfo.crsCode)),
            zoom : 12,
            controlDefaultOpts: { zoom: false, rotate: false },
            controls: []
        }, options);

        // this._init();
        this.map = new Map({
            controls : defaults(this._options.controlDefaultOpts).extend(this._options.controls),
            layers : [
            ],
            target : this.mapId,
            view : new View({
                projection: getProjection(this._backgroundInfo.crsCode),
                center: this._options.center,
                zoom: this._options.zoom,
                resolutions: this._backgroundInfo.resolutions
            })
        });
    }

    // /**
    //  * 객체 생성 시 초기화 함수
    //  * @private
    //  */
    // _init() {
    //     this.map = new Map({
    //         controls : defaults(this._options.controlDefaultOpts).extend(this._options.controls),
    //         layers : [
    //         ],
    //         target : this.mapId,
    //         view : new View({
    //             projection: getProjection(this._backgroundInfo.crsCode),
    //             center: this._options.center,
    //             zoom: this._options.zoom,
    //             resolutions: this._backgroundInfo.resolutions
    //         })
    //     });
    // }

    /**
     * map 에 layer 목록 추가
     * @param layers {{layerName: Layer}}
     */
    addLayers(layers: { [key:string]: Layer } | Layer[]) {
        (Array.isArray(layers) ? layers : Object.values(layers)).forEach(layer => {
            this.map.addLayer(layer);
        })
    }

    removeLayers(layers: { [key:string]: Layer } | Layer[]) {
        (Array.isArray(layers) ? layers : Object.values(layers)).forEach(layer => {
            this.map.removeLayer(layer);
        });
    }

    /**
     * 맵 클릭 이벤트 셋팅
     * @param cb {Function}
     * @param hitTolerance {int} [hitTolerance=0]
     */
    setMapClickEvtHandle(cb: Function, hitTolerance: number) {
        hitTolerance = hitTolerance || 0;
        this.map.on('click', evt => {
            if (evt.pixel) {
                this.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
                    cb(feature, layer);
                }, { hitTolerance : hitTolerance });
            }
        });
    }

    /**
     *
     * @returns {*|Map}
     */
    getMap(): Map {
        return this.map;
    }

    /**
     *
     * @returns {*|View}
     */
    getView(): View {
        return this.map.getView();
    }

    /**
     * 지도 컨테이너 크기 변경 시 map 리사이징
     */
    updateSize() {
        this.map.updateSize();
    }

    // /**
    //  * 좌표로 이동
    //  * @param coords {}
    //  * @returns {import("./coordinate.js").Coordinate}
    //  */
    // moveToCoord(coords: number[]): number[] {
    //     const currentCoord = fromLonLat(coords, getProjection('EPSG:3857'));
    //
    //     this.getView().animate({
    //         center: currentCoord,
    //         duration: 200
    //     });
    //
    //     return currentCoord;
    // }

    // /**
    //  * zoom Level 조정
    //  * @param diff {int}
    //  */
    // zoomInOut(diff: number) {
    //     const currentZoomLvl = this.getView().getZoom();
    //     if (currentZoomLvl && currentZoomLvl !== 0) {
    //         this.getView().setZoom(currentZoomLvl + diff);
    //     }
    // }

    /**
     *
     * @param center
     * @param source
     */
    setCenter(center: import('ol/coordinate').Coordinate, source: ProjectionLike = 'EPSG:4326') {
        this.getView().setCenter(transform(center, source, this._backgroundInfo.crsCode));
    }

    // /**
    //  * 위치 이동(부드럽게)
    //  * @param location
    //  * @param done
    //  */
    // flyTo(location: number[], done: Function) {
    //     const duration = 200;
    //     const view = this.getView();
    //     let parts = 2;
    //     let called = false;
    //     function callback(complete: any) {
    //         parts -= 2;
    //         if (called) {
    //             return;
    //         }
    //         if (parts === 0 || !complete) {
    //             called = true;
    //             if (done) {
    //                 if (typeof done === "function") done(complete);
    //             }
    //         }
    //     }
    //     view.animate({
    //         center: location,
    //         duration
    //     }, callback);
    // }

    moveByGeoJson(geom: any, dataProjection: ProjectionLike = this._backgroundInfo.crsCode, kind: string = 'temp') {
        this.map.getLayers().forEach((layer: any) => {
            if (layer.get('kind') === kind) {
                layer.getSource().clear(true);
                const ft = new GeoJSON().readFeature(geom, {
                    dataProjection,
                    featureProjection: this._backgroundInfo.crsCode
                });

                this.fitToExtent(ft.getGeometry()?.getExtent(), this._backgroundInfo.crsCode);

                layer.getSource().addFeature(ft);
            }
        });
    }

    getSourceByKind(kind: string) {
        return (this.map.getLayers().getArray().find(
            layer => layer.get('kind') === kind
        ) as VectorLayer).getSource();
    }

    selectByFeature(feature: import('ol/Feature').FeatureLike, kind: string = 'temp') {
        const sc = this.getSourceByKind(kind);
        sc.clear(true);
        sc.addFeature(feature as Feature);
    }

    featureToGeoJson(feature: import('ol/Feature').FeatureLike) {
        feature = <import('ol').Feature>feature;
        return new GeoJSON().writeFeatureObject(feature);
    }

    featureToWKT(feature: import('ol/Feature').FeatureLike, featureProjection: ProjectionLike = this._backgroundInfo.crsCode) {
        feature = <import('ol').Feature>feature;
        return new WKT().writeFeature(feature, {
            dataProjection: this._backgroundInfo.crsCode,
            featureProjection
        });
    }

    /**
     * extent 값으로 화면 맞추기
     * @param extent
     * @param source
     */
    fitToExtent(extent: Extent | undefined, source: ProjectionLike = 'EPSG:4326') {
        if (extent) {
            this.getView().fit(transformExtent(extent, source, this._backgroundInfo.crsCode));
        }
    }

    /**
     * target Id 변경
     * @param mapId {string}
     */
    setTarget(mapId: string) {
        if (this.getMap().getTarget() !== mapId) {
            this.getMap().setTarget(mapId);
        }
    }

    moveStartEventListener: any;
    moveEndEventListener: any;
    onSyncView(targetMap: Map) {
        this.moveStartEventListener = (evt: any) => {
            this.map.on('moveend', this.moveEndEventListener);
        };
        this.moveEndEventListener = (evt: any) => {
            this.map.un('moveend', this.moveEndEventListener);
            targetMap.getView().setCenter(this.map.getView().getCenter());
            targetMap.getView().setZoom(this.map.getView().getZoom() ?? 0);
        };

        this.map.on('movestart', this.moveStartEventListener);
    }

    offSyncView() {
        this.map.un('movestart', this.moveStartEventListener);
    }


    addOverlay(overlay: Overlay) {
        this.map.addOverlay(overlay);
    }
}

export default InnoMap;