import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Table, { Size } from "@material-ui/core/Table";
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import InnoTableToolbar from "./InnoTableToolbar";
import InnoTableHead from "./InnoTableHead";
import InnoTablePagination from "./InnoTablePagination";

// interface InnoTableListProps {
//     tableSize: Size;
//     tableHeight: string;
//     toolbar: any;
//     headCells: any[];
//     onRowClick: any;
//     onReloadClick: any;
//     onContextMenu: any;
//     selectedValue: any;
//     rowKey: string;
//     cellFormatter: any;
//     rowsPerPageOptions: any;
//     isPagination: boolean;
//     emptyRowHeight: number;
//     noDataText: string;
//     rows: any[];
//     order: 'asc' | 'desc';
//     orderBy: string;
// }

interface InnoTableListProps {
    tableSize?: Size;
    tableHeight?: string;
    toolbar?: any;
    headCells: any[];
    onRowClick?: any;
    onReloadClick?: any;
    onContextMenu?: any;
    selectedValue?: any;
    rowKey: string;
    cellFormatter?: any;
    rowsPerPageOptions?: any;
    isPagination?: boolean;
    emptyRowHeight?: number;
    noDataText?: string;
    rows: any[];
    order?: 'asc' | 'desc';
    orderBy?: string;
}

type initStateType = {
    page: number;
    rowsPerPage: number;
    order?: string;
    orderBy?: string;
    customRows: any[];
}
const initState: initStateType = {
    page: 0,
    rowsPerPage: 5,
    order: "",
    orderBy: "",
    customRows: []
};

const useStyles = makeStyles((theme) => ({
    table: {
        // minWidth: 500,
        margin:0,
        "& tr": {
            userSelect: 'none'
        }
    },
    layout: {
        height: '100%',
        maxHeight: '265px'
    },
}));

const InnoTableList = (props: InnoTableListProps): React.ReactElement => {
    const classes = useStyles();
    const {
        tableSize = 'small',
        tableHeight = '100%',
        toolbar,
        headCells,
        onRowClick = () => {},
        onReloadClick,
        onContextMenu = () => {},
        selectedValue,
        rowKey,
        cellFormatter,
        rowsPerPageOptions,
        isPagination = true,
        emptyRowHeight = 45,
        noDataText = 'No records to display',
        rows,
        order,
        orderBy
    } = props;

    const [state, setState] = useState(initState);

    const descendingComparator = useCallback((a: any, b: any, orderBy?: string) => {
        if (orderBy) {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
        }
        return 0;
    }, []);

    const getComparator = useCallback((order?: string, orderBy?: string) => {
        return order === 'desc'
            ? (a: any, b: any) => descendingComparator(a, b, orderBy)
            : (a: any, b: any) => -descendingComparator(a, b, orderBy);
    }, []);

    const stableSort = useCallback((array: any[], comparator: any) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }, []);

    const handleChangePage = useCallback((event: React.MouseEvent, newPage: number) => {
        setState({
            ...state,
            page: newPage
        });
    }, [state]);

    const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        setState({
            ...state,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        });
    }, [state]);

    const handleRequestSort = useCallback((event, property) => {
        const { order, orderBy } = state;
        const isAsc = orderBy === property && order === 'asc';
        setState({
            ...state,
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        });
    }, [state]);

    const handleChangeSearchTextField = useCallback((event) => {
        const searchText = event.target.value;
        if (rows.length > 0) {
            if (searchText !== "") {
                let searchRows = rows.filter(row => {
                    let bFlag = false;
                    if (searchText === "") {
                        return true;
                    } else {
                        if (typeof toolbar.handleChange === "function") {
                            bFlag = toolbar.handleChange(searchText, row);
                        } else {
                            Object.entries(row).some(([key, val]) => {
                                if (typeof val === "string") {
                                    return (bFlag = val.indexOf(searchText) !== -1);
                                }
                            });
                        }

                        return bFlag;
                    }
                });
                setState({
                    ...state,
                    customRows: searchRows
                });
            } else {
                setState({
                    ...state,
                    customRows: rows
                });
            }
        }
    }, [rows, toolbar, state]);

    const isRowSelected = useCallback((data) => {
        let bFlag = false;
        if (selectedValue instanceof Array) {
            bFlag = selectedValue.includes(data);
        } else {
            bFlag = selectedValue === data;
        }

        return bFlag;
    }, [selectedValue]);

    useEffect(() => {
        setState({
            ...state,
            order,
            orderBy
        });
    }, []);

    useEffect(() => {
        setState({
            ...initState,
            customRows: rows
        });
    }, [rows]);

    const emptyRows = useMemo(() => {
        return state.rowsPerPage - Math.min(state.rowsPerPage, state.customRows.length - state.page * state.rowsPerPage);
    }, [state.rowsPerPage, state.customRows, state.page]);

    return (
        <>
            {toolbar && (<InnoTableToolbar title={toolbar.title} onChange={handleChangeSearchTextField}/>)}
            <TableContainer className={classes.layout} style={{ minHeight: tableHeight, maxHeight: tableHeight }}>
                <Table className={classes.table} stickyHeader aria-label="sticky table" size={tableSize}>
                    <InnoTableHead
                        headCells={headCells}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {state.customRows.length !== 0 ?
                            [
                                (state.rowsPerPage > 0 && isPagination
                                        ? stableSort(state.customRows, getComparator(order, orderBy)).slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage)
                                        : state.customRows
                                ).map((row) => {
                                    return (
                                        <TableRow key={row[rowKey]} hover={true} selected={isRowSelected(row[rowKey])} onClick={onRowClick(row)} onContextMenu={onContextMenu(row)}>
                                            {headCells.map((column, idx) => {
                                                const value = row[column.id];
                                                return (
                                                    cellFormatter ?
                                                        (
                                                            cellFormatter(value, row, column)
                                                        ) :
                                                        (
                                                            <TableCell key={column.id} align={column.align} style={{
                                                                maxWidth: column.maxWidth,
                                                                width: column.width
                                                            }}>
                                                                {column.format ? column.format(value, row) : value}
                                                            </TableCell>
                                                        )
                                                );
                                            })}
                                        </TableRow>
                                    )
                                }),
                                emptyRows > 0 && (
                                    <TableRow key="emptyRow" style={{ height: emptyRowHeight * emptyRows }}>
                                        <TableCell colSpan={headCells.length}>
                                        </TableCell>
                                    </TableRow>
                                )
                            ] :
                            (
                                <TableRow key="noData" style={{ height: emptyRowHeight }}>
                                    <TableCell colSpan={headCells.length} style={{ textAlign: 'center' }}>
                                        {noDataText}
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {isPagination && (
                <InnoTablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    count={state.customRows.length}
                    rowsPerPage={state.rowsPerPage}
                    page={state.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    onReloadClick={onReloadClick}
                />
            )}

        </>
    );
};

InnoTableList.propTypes = {
    headCells: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    rowKey: PropTypes.string.isRequired,
    toolbar: PropTypes.object,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    onRowClick: PropTypes.func,
    onReloadClick: PropTypes.func,
    onContextMenu: PropTypes.func,
    selectedValue: PropTypes.any,
    cellFormatter: PropTypes.func,
    tableHeight: PropTypes.string,
    tableSize: PropTypes.string,
    isPagination: PropTypes.bool,
    emptyRowHeight: PropTypes.number,
    noDataText: PropTypes.string,
};

export default InnoTableList;