import React, { } from 'react';
import { Link } from "react-router-dom";

import './resources/css/error.scss';

const NotFound = (): React.ReactElement => {
    return (
        <div className="wrap">
            <div className="error-wrap">
                <div className="error-area">
                    <span>4 0 4</span> ERROR
                </div>
                <div className="message-area">
                    <div className="tit">페이지를 찾을 수 없습니다.</div>
                    <div className="cont">
                        페이지가 존재하지 않거나, 사용할 수 없는 페이지 입니다.<br/>
                        입력하신 주소가 정확한지 다시 한 번 확인해주세요.<br/>
                        <Link to={'/'} >메인화면 이동</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFound;