import { atom, selector } from 'recoil';
import {
    findProjects,
    SearchParamsInterface,
    findProject,
    ProjectStats
} from 'service/ProjectService';
import * as codeService from 'service/CodeService';

export const codeInfoListSelector = selector({
    key: 'codeInfoListSelector',
    get: async () => {
        const { data } = await codeService.findCodeInfo('sggCde');
        return data;
    }
});

export const projectSearchState = atom<SearchParamsInterface>({
    key: 'projectSearchState',
    default: {}
});

export const forceReloadProjectListState = atom<number>({
    key: 'forceReloadProjectListState',
    default: 0
});

export const projectListState = selector({
    key: 'projectListState',
    get: async ({ get }) => {
        get(forceReloadProjectListState);
        const searchParams = get(projectSearchState);
        const { data } = await findProjects(searchParams);
        return data;
    },
    set: ({ set }, newValue = 0) => {
        set(forceReloadProjectListState, Math.random());
    }
});

export interface SelectedProject {
    projectId: string;
    year?: number;
    detectionItem?: string;
    title?: string;
}

export const selectedProjectState = atom<SelectedProject>({
    key: 'selectedProjectState',
    default: {
        projectId: '',
        year: 0,
        detectionItem: '',
        title: ''
    }
});

export const detailProjectIdState = atom<string>({
    key: 'detailProjectIdState',
    default: ''
});

export const forceReloadDetailProjectState = atom<number>({
    key: 'forceReloadDetailProjectState',
    default: 0
});

export const detailProjectSelector = selector({
    key: 'detailProjectSelector',
    get: async ({ get }) => {
        const projectId = get(detailProjectIdState);
        const { data: detail } = await findProject(projectId);
        const { data: console } = await findProject(projectId, 'log');

        return { detail, console: Object.values(console).join('\n').replace(/\\t/g,'&nbsp;&nbsp;').replace(/\\r/g,"<br />").replace(/\\n/g,"<br />").replace('<','&lt;').replace('>','&gt;') };
    },
    set: ({ set }, newValue = 0) => {
        set(forceReloadDetailProjectState, Math.random());
    }
});

export const detailProjectStatsSelector = selector({
    key: "detailProjectStatsSelector",
    get: async ({ get }) => {
        const projectId = get(detailProjectIdState);
        const { data } = await findProject(projectId, "stats");

        return { stats: data as ProjectStats };
    }
});
