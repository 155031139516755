import { Feature } from 'ol';
// import {
//     Point,
//     // LineString,
//     // LinearRing,
//     // Polygon,
//     // MultiPoint,
//     // MultiLineString,
//     // MultiPolygon
// } from 'ol/geom';

import { Layer, Vector as VectorLayer, Tile as TileLayer } from 'ol/layer';
import { Vector as VectorSource, XYZ } from 'ol/source';
import { WKT, GeoJSON } from 'ol/format';

import { Fill, Stroke, Style } from 'ol/style';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import { transformExtent } from 'ol/proj';
import { Extent } from 'ol/extent';

class InnoLayer {
    private readonly _layers: { [key:string]: Layer };
    // private _imageLayers: ImageLayer[] = [];

    constructor() {
        this._layers = {};
        // this._init();
    }

    /**
     * ol Feature로 읽기 위한 옵션
     * @type {{geojson: GeoJSON, wkt: WKT, readFeatureOpt: {featureProjection: string, dataProjection: string}}}
     * @private
     */
    private _format = {
        geojson: new GeoJSON(),
        wkt: new WKT(),
        readFeatureOpt: {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:5179',
        },
    };

    private _wfsMaxResolution: number = 0.7;

    get wfsMaxResolution(): number {
        return this._wfsMaxResolution;
    }

    getWfsLayer(layerId: number | string, properties: any, getGeoJson: (extent: Extent) => any) {
        const source = new VectorSource({
            loader: async (extent, resolution, projection) => {
                source.clear(true);
                const geoJson = await getGeoJson(
                    transformExtent(extent,
                        this._format.readFeatureOpt.featureProjection,
                        this._format.readFeatureOpt.dataProjection)
                );
                if (geoJson.features) {
                    const fs = this.getFeaturesFromSpData(geoJson);
                    source.addFeatures(fs);
                }
            },
            strategy: bboxStrategy
        });

        const layer = new VectorLayer({
            source,
            visible: true,
            zIndex: 200,
            maxResolution: this._wfsMaxResolution
        });

        layer.setProperties({
            ...properties,
            'id': layerId,
            'kind': 'wfs'
        });

        return layer;
    }

    getVectorLayer() {
        const layer = new VectorLayer({
            source: new VectorSource(),          
            visible: true,
            zIndex: 201,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(0,0,255,0.4)'
                }),
                stroke: new Stroke({
                    color: 'rgba(0,0,255,1)',
                    width: 2
                })
            })
        });

        layer.setProperties({
            'kind': 'temp'
        });
        
        return layer;
    }

    /**
     *
     * @param layerId
     * @param url
     * @param extent
     * @param minZoom
     * @param maxZoom
     * @param visible
     */
    getTmsLayer(layerId: number | string, url: string, extent: Extent, minZoom: number, maxZoom: number, visible: boolean = true) {
        extent = transformExtent(extent, 'EPSG:4326', this._format.readFeatureOpt.featureProjection);

        const tmsLayer = new TileLayer({
            source: new XYZ({
                url,
                minZoom,
                maxZoom,
            }),
            extent,
            visible,
            zIndex: 100,
        });
        tmsLayer.set('id', layerId);
        return tmsLayer;
    }

    getAnalysisStyle (feature: Feature, resolution: number) {
        //@ts-ignore
        const item = feature.values_.detection_item;
        //@ts-ignore
        const inferenceData = document.getElementsByName('isInference')[0].value;
        //@ts-ignore
        const inference = feature.values_.confidence;
        let inferecneChk = false;
        if(inferenceData == "49"){
            if(inference <= Number(inferenceData)){
                inferecneChk = true;
            }
        } else {
            if(inference >= Number(inferenceData)){
             inferecneChk = true;
            }            
        }

        //type
        //@ts-ignore
        const typeDataA  = document.getElementsByName('A')[0].checked;
        //@ts-ignore
        const typeDataB  = document.getElementsByName('B')[0].checked;
        //@ts-ignore
        const typeDataC  = document.getElementsByName('C')[0].checked;
        //@ts-ignore
        const typeDataD  = document.getElementsByName('D')[0].checked;
        //@ts-ignore
        const type = feature.values_.type;

        if(type == "A"){
            if(!typeDataA){
                return new Style({});    
            }
        } else if(type == "B"){
            if(!typeDataB){
                return new Style({});    
            }
        } else if(type == "C"){
            if(!typeDataC){
                return new Style({});    
            }
        } else if(type == "D"){
            if(!typeDataD){
                return new Style({});    
            }
        }
   

        //A = 신축 , B = 소멸 , C = 갱신 , D = 철거
        //1 = 신축 , 2 = 소멸 , 3 = 갱신 , 4 = 철거
        if(item == "BuildingChange"){

            if(inferenceData == 0){
                //@ts-ignore
                if(feature.values_.type == "A"){
                    return new Style({
                        fill: new Fill({
                            color: 'rgba(119, 232, 164,0.2)'
                        }),
                        stroke: new Stroke({
                            color: 'rgba(119, 232, 164,1)',
                            width: 3
                        })
                    });
                //@ts-ignore
                } else if(feature.values_.type == "B"){
                    return new Style({
                        fill: new Fill({
                            color: 'rgba(245, 11, 11,0.2)',
                        }),
                        stroke: new Stroke({
                            color: 'rgba(245, 11, 11,1)',
                            width: 3
                        })
                    });
                //@ts-ignore
                } else if(feature.values_.type == "C"){
                    return new Style({
                        fill: new Fill({
                            color: 'rgba(247, 252, 12,0.2)',
                        }),
                        stroke: new Stroke({
                            color: 'rgba(247, 252, 12,1)',
                            width: 3
                        })
                    });
                } else{
                    return new Style({
                        fill: new Fill({
                            color: 'rgba(158, 9, 250,0.2)',
                        }),
                        stroke: new Stroke({
                            color: 'rgba(158, 9, 250,1)',
                            width: 3
                        })
                    });     
                }
            } else if (inferecneChk){
                //0이 아니면
                  //@ts-ignore
                if(feature.values_.type == "A"){
                    return new Style({
                        fill: new Fill({
                            color: 'rgba(119, 232, 164,0.2)',
                        }),
                        stroke: new Stroke({
                            color: 'rgba(119, 232, 164,1)',
                            width: 3
                        })
                    });
                //@ts-ignore
                } else if(feature.values_.type == "B"){
                    return new Style({
                        fill: new Fill({
                            color: 'rgba(245, 11, 11,0.2)',
                        }),
                        stroke: new Stroke({
                            color: 'rgba(245, 11, 11,1)',
                            width: 3
                        })
                    });
                //@ts-ignore
                } else if(feature.values_.type == "C"){
                    return new Style({
                        fill: new Fill({
                            color: 'rgba(247, 252, 12,0.2)',
                        }),
                        stroke: new Stroke({
                            color: 'rgba(247, 252, 12,1)',
                            width: 3
                        })
                    });
                } else{
                    return new Style({
                        fill: new Fill({
                            color: 'rgba(158, 9, 250,0.2)',
                        }),
                        stroke: new Stroke({
                            color: 'rgba(158, 9, 250,1)',
                            width: 3
                        })
                    });     
                }
            } else {
                return new Style({});       
            }

            

        } else {
            return new Style({
                fill: new Fill({
                    color: 'rgba(255,255,0,0.2)',
                }),
                stroke: new Stroke({
                    color: 'rgba(255,255,0,1)',
                    width: 3
                })
            });        
        }
        //폴리곤 그리는 부분
        
    }

    getCadStyle (feature: Feature, resolution: number) {
        return new Style({
            fill: new Fill({
                color: 'rgb(92,83,83)'
            }),
            stroke: new Stroke({
                color: 'rgba(0,0,255,0.8)',
                width: 1.5
            })
        });
    }
    // getLayers() {
    //     return this._layers;
    // }

    // /**
    //  *
    //  * @type {{flightLog: Collection, detection: Collection, route: Collection, bbox: Collection}}
    //  * @private
    //  */
    // _features = {
    //     flightLog: new Collection(),
    //     bbox: new Collection(),
    //     detection: new Collection(),
    //     route: new Collection(),
    // };

    // /**
    //  *
    //  * @type {{}}
    //  * @private
    //  */
    // _source: { [key: string]: VectorSource } = {
    //     flightLog: new VectorSource({ features: this._features.flightLog }),
    //     bbox: new VectorSource({ features: this._features.bbox }),
    //     detection: new VectorSource({ features: this._features.detection }),
    //     route: new VectorSource({ features: this._features.route }),
    //     flightLogSelect: new VectorSource(),
    //     bboxSelect: new VectorSource(),
    //     detectionSelect: new VectorSource(),
    //     gpsLocation: new VectorSource(),
    //     boundary: new VectorSource({
    //         loader: async (extent, resolution, projection) => {
    //             this._source.boundary.clear(true);
    //             const { data: { result } } = await getGeojson('boundary', transformExtent(extent, 'EPSG:3857', 'EPSG:5181'));
    //             if (result.geojson.features) {
    //                 const fs = this._format.geojson.readFeatures(result.geojson, {
    //                     dataProjection: 'EPSG:5181',
    //                     featureProjection: 'EPSG:3857'
    //                 });
    //                 this._source.boundary.addFeatures(fs);
    //             }
    //         },
    //         strategy: bboxStrategy,
    //     }),
    //     building: new VectorSource({
    //         loader: async (extent, resolution, projection) => {
    //             this._source.building.clear(true);
    //             const { data: { result } } = await getGeojson('building', transformExtent(extent, 'EPSG:3857', 'EPSG:5181'));
    //             if (result.geojson.features) {
    //                 const fs = this._format.geojson.readFeatures(result.geojson, {
    //                     dataProjection: 'EPSG:5181',
    //                     featureProjection: 'EPSG:3857'
    //                 });
    //                 this._source.building.addFeatures(fs);
    //             }
    //
    //         },
    //         strategy: bboxStrategy,
    //     })
    // };

    // /**
    //  *
    //  * @type {{}}
    //  * @private
    //  */
    // _layer: { [key: string]: any } = {
    //     flightLog: {
    //         style: this._createFlightLogStyle(drone, undefined),
    //         zIndex: 2502,
    //     },
    //     route: {
    //         style: new Style({
    //             stroke: new Stroke({
    //                 color: 'rgba(99,255,55,0.6)',
    //                 lineDash: [4, 4],
    //                 width: 2,
    //             }),
    //         }),
    //         zIndex: 1999,
    //     },
    //     bbox: {
    //         style: this._createImageFootPrintStyle(),
    //         zIndex: 1500,
    //     },
    //     detection: {
    //         style: this._detectionStyleFunc,
    //         zIndex: 3000,
    //     },
    //     flightLogSelect: {
    //         style: this._createFlightLogStyle(drone, undefined, 'yellow'),
    //         zIndex: 2001,
    //     },
    //     bboxSelect: {
    //         style: this._createImageFootPrintStyle(6),
    //         zIndex: 1501,
    //     },
    //     detectionSelect: {
    //         style: this._detectionStyleFunc,
    //         zIndex: 3001,
    //     },
    //     gpsLocation: {
    //         style: new Style({
    //             image: new Icon({
    //                 anchor: [0.5, 0.5],
    //                 crossOrigin: 'anonymous',
    //                 src: marker,
    //             }),
    //         }),
    //         zIndex: 4000,
    //     },
    //     boundary: {
    //         kind: 'continuous',
    //         text: '공원경계',
    //         zIndex: 2501,
    //         visible: false,
    //         style: new Style({
    //             stroke: new Stroke({
    //                 color: `rgba(0,0,255,0.4)`,
    //                 width: 1.5
    //             }),
    //             fill: new Fill({
    //                 color: `rgba(255,255,255,0.3)`,
    //             })
    //         })
    //     },
    //     building: {
    //         kind: 'continuous',
    //         text: '건물',
    //         zIndex: 2501,
    //         visible: false,
    //         style: new Style({
    //             stroke: new Stroke({
    //                 color: `rgba(108, 104, 104, 0.7)`,
    //                 width: 1
    //             }),
    //             fill: new Fill({
    //                 color: `rgba(26, 26, 219, 0.4)`,
    //             })
    //         })
    //     }
    // };

    // /**
    //  * 비행로그 스타일 정의
    //  * @param src {svg | string}
    //  * @param img {svg | string}
    //  * @param color {string}
    //  * @returns {Style}
    //  * @private
    //  */
    // _createFlightLogStyle(src, img, color) {
    //     let iconOpts = {
    //         anchor: [0.5, 0.5],
    //         crossOrigin: 'anonymous',
    //         src: src,
    //         img: img,
    //         imgSize: img ? [img.width, img.height] : undefined,
    //     };
    //
    //     if (color) {
    //         iconOpts.color = color;
    //     }
    //
    //     return new Style({
    //         image: new Icon(iconOpts),
    //     });
    // }

    // /**
    //  * 이미지 풋프린트 스타일 정의
    //  * @param width {float}
    //  * @returns {Style}
    //  * @private
    //  */
    // _createImageFootPrintStyle(width) {
    //     return new Style({
    //         stroke: new Stroke({
    //             color: width ? 'rgba(3, 0, 102, 1.0)' : 'rgba(0, 0, 255, 0.6)',
    //             width: width || 2,
    //         }),
    //     });
    // }

    // /**
    //  * 검출객체 스타일 정의
    //  * @param feature {Feature}
    //  * @param resolution {float}
    //  * @returns {Style}
    //  * @private
    //  */
    // _detectionStyleFunc(feature, resolution) {
    //     const { rgbaColor, text } = DetectionUtils.getDataByObjType(feature.get('objectType'), 0.7);
    //     const { rgbaColor: alphaRgba } = DetectionUtils.getDataByObjType(feature.get('objectType'), 0.2);
    //     return new Style({
    //         stroke: new Stroke({
    //             color: rgbaColor,
    //             width: 2.5
    //         }),
    //         fill: new Fill({
    //             color: alphaRgba
    //         }),
    //         text: new Text({
    //             font: '12px Calibri,sans-serif',
    //             text: text,
    //             fill: new Fill({
    //                 color: rgbaColor,
    //             }),
    //             stroke: new Stroke({
    //                 color: '#000',
    //                 width: 0.5,
    //             }),
    //         })
    //     });
    // }

    // /**
    //  * 객체 생성 시 초기화 함수
    //  * @private
    //  */
    // private _init() {
    //     Object.entries(this._layer).forEach(([layerName, info]) => {
    //         const layer = new VectorLayer({
    //             ...info,
    //             name: layerName,
    //             source: this._source[layerName],
    //         });
    //
    //         this._layers[layerName] = layer;
    //     });
    // }

    // /**
    //  * 레이어 목록
    //  * @returns {{layerName: VectorLayer}|*}
    //  */
    // getLayers() {
    //     return this._layers;
    // }

    // getLayerByName(map: Map, name: string) {
    //     let findLayer;
    //     map.getLayers().forEach(layer => {
    //         if (layer.get('name') === name) {
    //             findLayer = layer;
    //         }
    //     })
    //     return findLayer;
    // }

    // /**
    //  * 이름으로 소스(Source) 반환
    //  * @param name {string}
    //  * @returns {VectorSource}
    //  */
    // getSourceByName(name: string) {
    //     return this._source[name];
    // }

    // /**
    //  * 지도상의 모든 벡터소스 초기화
    //  */
    // sourceAllClear() {
    //     Object.entries(this._source).forEach(([name, source]) => {
    //         source.clear();
    //     });
    // }

    // /**
    //  * 배열형태의 소스 이름들을 매개변수로 받아 source clear 처리
    //  * @param sourceNames {Array.<string>} 배열 소스 이름
    //  */
    // sourceClearByNames(sourceNames: string[]) {
    //     for (const sourceName of sourceNames) {
    //         this._source[sourceName].clear();
    //     }
    // }

    // /**
    //  * 지도상의 이미지 레이어 전체 제거
    //  * @param map {Map}
    //  */
    // removeImageLayer(map: Map) {
    //     this._imageLayers.forEach((imgLyr) => {
    //         map.removeLayer(imgLyr);
    //     });
    //
    //     this._imageLayers = [];
    // }

    // /**
    //  * 지도상의 이미지 레이어 이름으로 제거
    //  * @param map {Map}
    //  * @param name {string}
    //  */
    // removeImageLayerByName(map: Map, name: string) {
    //     let idx = 0;
    //     let bFlag = false;
    //     for (const imgLyr of this._imageLayers) {
    //         if (imgLyr.get('name') === `image-${name}`) {
    //             map.removeLayer(imgLyr);
    //             bFlag = true;
    //             break;
    //         }
    //         idx++;
    //     }
    //
    //     if (bFlag) this._imageLayers.splice(idx, 1);
    // }

    // /**
    //  * 지도상의 이미지 레이어 추가
    //  * @param map {Map}
    //  * @param url {string}
    //  * @param extent {Array.<double>}
    //  * @param projectId {string} uuid
    //  * @param opacity {number}
    //  * @param kind {string}
    //  */
    // addImageLayer(map: Map, url: string, extent: [number, number, number, number], projectId: string, opacity: number, kind = 'directImage') {
    //     // if (this._imageLayers.length > 4) {
    //     //     const delLayer = this._imageLayers.shift();
    //     //     map.removeLayer(delLayer);
    //     // }
    //     const imageLayerProperties = {
    //         projectId,
    //         kind,
    //         opacity,
    //         source: new Static({
    //             url: url,
    //             projection: 'EPSG:3857',
    //             imageExtent: extent,
    //         }),
    //         visible: true,
    //         minResolution: 0.01,
    //         maxResolution: 5,
    //         zIndex: 2500,
    //     };
    //     const imgLayer = new ImageLayer(imageLayerProperties);
    //     // this._imageLayers.push(imgLayer);
    //     map.addLayer(imgLayer);
    // }

    // setOpacityDirectImageLayer(map, opacity) {
    //     map.getLayers().forEach(layer => {
    //         if (layer.get('kind') === 'directImage') {
    //             layer.setOpacity(opacity);
    //         }
    //     });
    // }

    // /**
    //  * source 의 extent 값에 의한 중심좌표
    //  * @param sourceName {string}
    //  * @returns {import("./coordinate.js").Coordinate}
    //  */
    // getCenterByExtent(sourceName: string) {
    //     return olExtent.getCenter(this._source[sourceName].getExtent());
    // }

    getFeaturesFromSpData(spData: any, formatKind: 'geojson' | 'wkt' = 'geojson') {
        return this._format[formatKind].readFeatures(spData, this._format.readFeatureOpt);
    }

    // /**
    //  * 지도상의 length 에 따른 피처 개수만 표현
    //  * @param name {string}
    //  * @param feature {Feature}
    //  * @param length {int}
    //  */
    // setFeatures(name, feature, length) {
    //     length = length || 5;
    //     const features = this._features[name];
    //     if (features.getLength() > length - 1) {
    //         features.removeAt(0);
    //     }
    //     features.push(feature);
    //     if (name === 'flightLog') {
    //         const routeFeatures = this._features.route;
    //         routeFeatures.clear();
    //         routeFeatures.push(new Feature(new LineString(features.getArray().map((logPoint) => logPoint.getGeometry().getCoordinates()))));
    //     }
    // }

    // /**
    //  * 좌표로 Feature 생성
    //  * @param coordinates {Array.<double>}
    //  * @param properties {{}}
    //  * @returns {Feature}
    //  */
    // getPointFtFromCoordinates(coordinates: number[], properties: any) {
    //     properties = properties || {};
    //     const feature = new Feature(new Point(coordinates));
    //     Object.entries(properties).forEach(([key, value]) => {
    //         feature.set(key, value);
    //     });
    //     return feature;
    // }

    // /**
    //  * 소스명으로 넘겨받은 Feature 추가
    //  * @param feature {Feature}
    //  * @param name {string}
    //  */
    // addFeatureWithSourceName(feature: Feature, name: string) {
    //     this._source[name].addFeature(feature);
    // }

    // /**
    //  * 이미지 레이어 On/Off
    //  * @param name {string}
    //  * @param visible {boolean}
    //  */
    // setImageLayerVisibleByName(name: string, visible: boolean) {
    //     this._imageLayers.forEach((imgLyr) => {
    //         if (imgLyr.get('name') === `image-${name}`) {
    //             imgLyr.setVisible(visible || !imgLyr.getVisible());
    //         }
    //     });
    // }

    // getVectorLayer(params: any) {
    //     return new VectorLayer({
    //         ...params,
    //         source: new VectorSource()
    //     });
    // }

    // getDirectImageLayers(map, projectId) {
    //     const imageLayers = [];
    //     map.getLayers().forEach(layer => {
    //         if (layer.get('projectId') === projectId && layer.get('kind') === 'directImage') {
    //             imageLayers.push(layer);
    //         }
    //     });
    //     return imageLayers;
    // }

    // getDetectionStyle(feature, resolution) {
    //     const classType = feature.get('classType') || feature.get('class');
    //     if (classType === 1) {
    //         return new Style({
    //             stroke: new Stroke({
    //                 color: `rgba(255,255,0,1)`,
    //                 width: 1.5
    //             }),
    //             fill: new Fill({
    //                 color: `rgba(255,255,0,0.3)`,
    //             })
    //         });
    //     } else if (classType === 3) {
    //         return new Style({
    //             stroke: new Stroke({
    //                 color: `rgba(255,0,0,1)`,
    //                 width: 1.5
    //             }),
    //             fill: new Fill({
    //                 color: `rgba(255,0,0,0.3)`,
    //             })
    //         });
    //     }
    // }

    // getContinuousLayers() {
    //     const layers = [];
    //     Object.entries(this._layers).forEach(([layerName, layer]) => {
    //         if (layer.get('kind') === 'continuous') {
    //             layers.push(layer);
    //         }
    //     });
    //     return layers;
    // }
}

export default InnoLayer;
