import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import ReplayIcon from "@material-ui/icons/Replay";
import PropTypes from "prop-types";

interface InnoTablePaginationActionsProps {
    count: number;
    onChangePage: any;
    page: number;
    rowsPerPage: number;
    onReloadClick: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        margin: theme.spacing(0)
    }
}));

const InnoTablePaginationActions = (props: InnoTablePaginationActionsProps): React.ReactElement => {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage, onReloadClick } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
            {onReloadClick && (
                <IconButton onClick={onReloadClick} aria-label="reload">
                    <ReplayIcon />
                </IconButton>
            )}
        </div>
    );
};

InnoTablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    onReloadClick: PropTypes.func
};

export default InnoTablePaginationActions;
