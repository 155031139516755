import { ThemeOptions } from "@material-ui/core";

const themesConfig: { [k: string]: ThemeOptions } = {
  light1: {
    palette: {
      type: 'light',
      common: { black: "#000", white: "#fff" },
      primary: {
        light: "#33678f",
        main: "#014274",
        dark: "#002e51",
        contrastText: "#fff"
      },
      secondary: {
        light: "rgba(240, 222, 192, 1)",
        main: "rgba(218, 158, 55, 1)",
        dark: "rgba(161, 132, 84, 1)",
        contrastText: "#fff"
      },
      background: {
        paper: "#FFFFFF",
        default: "#F0F7F7"
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff"
      },
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)"
      },
    },
    typography: {
      fontFamily: [
        'Pretendard',
        '"Noto Sans Korean"',
        "-apple-system",
        "BlinkMacSystemFont",
        '"Apple SD Gothic Neo"',
        '"Malgun Gothic"',
      ].join(",")
    }
  },
  dark1: {
    // common: { black: "#333", white: "#fff" },
    // type: "dark",
    palette: {
      type: 'dark',
      primary: {
        light: "#a6d4fa",
        main: "#90caf9",
        dark: "#648dae"
      },
      secondary: {
        light: "#f6a5c0",
        main: "#f48fb1",
        dark: "#aa647b",
        contrastText: "#FFFFFF"
      },
      action: {
        active: "rgba(255,255,255,1)",
        hover: "rgba(255,255,255,.08)",
        selected: "rgba(255,255,255,.16)",
        disabled: "rgba(255,255,255,.26)",
        disabledBackground: "rgba(255,255,255,.12)"
      },
      background: {
        paper: "rgba(48,48,48,.96)",
        default: "#646464"
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff"
      },
      text: {
        primary: "rgba(255,255,255, 1)",
        secondary: "rgba(255,255,255, 0.7)",
        disabled: "rgba(255,255,255, 0.5)",
        hint: "rgba(255,255,255, 0.38)"
      },
      divider: "rgba(255,255,255,.18)"
    },
    // status: {
    //   danger: "orange",
    // },
    typography: {
      fontFamily: [
        'Pretendard',
        '"Noto Sans Korean"',
        "-apple-system",
        "BlinkMacSystemFont",
        '"Apple SD Gothic Neo"',
        '"Malgun Gothic"'
      ].join(","),
    },
    overrides: {
      MuiCardHeader: {
        title: {
          fontSize: '1.0rem'
        },
      },
      MuiOutlinedInput: {
        root: {
          backgroundColor: "rgba(128, 128, 128, .6)",
          borderColor: "rgba(255, 255, 255, .4)",
          color: "rgba(255,255,255, 1)",
          borderRadius: "8px"
        },
      },
      MuiButton: {
        root: {
          borderRadius: "8px"
        }
      },
      MuiFormLabel: {
        root: {
          color: "rgba(255,255,255,.5)",
        }
      },
      MuiMenu: {
        list: {
          '&>li::nth-child(odd)': {
            background: '#fff',
          },
        }
      },
      MuiPopover: {
        paper: {

          "&::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(255,255,255,0.00)"
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "3px",
            backgroundColor: "rgba(255,255,255,.6)"
            //outline: '1px solid rgba(255,255,255,.9)',
          },
          "&:hover::-webkit-scrollbar-thumb": {
            borderRadius: "3px",
            backgroundColor: "rgba(255,255,255,.9)",
            "-webkit-box-shadow": "inset 0 0 6px rgba(255,255,255,0.30)"

            //outline: '1px solid rgba(255,255,255,.9)',
          },
          "&::-webkit-scrollbar-thumb:window-inactive": {
            backgroundColor: "rgba(255,255,255,.4)"
          }
        }
      }

    }
  },
  toolbar: {
    //common: { black: "#000", white: "#fff" },
    //type: "dark",
    palette: {
      type: 'dark',
      primary: {
        light: "#313640",
        main: "#20232a",
        dark: "#0f1014"
      },
      secondary: {
        light: "#3dbc98",
        main: "#0dac7f",
        dark: "#097858",
        contrastText: "#FFFFFF"
      },
      background: {
        paper: "rgba(48,48,48,.96)",
        default: "#646464"
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff"
      },
      text: {
        primary: "rgba(255,255,255, 0.92)",
        secondary: "rgba(255,255,255, 0.54)",
        disabled: "rgba(255,255,255, 0.38)",
        hint: "rgba(255,255,255, 0.38)"
      },
    },
    // status: {
    //   danger: "orange",
    // },
    typography: {
      fontFamily: [
        'Pretendard',
        '"Noto Sans Korean"',
        "-apple-system",
        "BlinkMacSystemFont",
        '"Apple SD Gothic Neo"',
        '"Malgun Gothic"'
      ].join(","),
    },
    overrides: {
      MuiIconButton: {
        root: {
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: "6px",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.3)"
          },
        },
      },
    },
  }
};

export default themesConfig;
