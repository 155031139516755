import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory  } from 'react-router-dom';
import Swal from "sweetalert2";
import './resources/css/login.scss';
import logo from "./resources/images/icon-logo.svg"

export default function LoginPage() {

   //cookie 
   const [cookies, setCookie, removeCookie,getCookie] = useCookies(['login']);
   const history = useHistory();
   //const navigate = useNavigate();

  //input에서 value를 담기 위한 state 생성
  const [account, setAccount] = useState({
    userId: "",
    userPw: "",
  });


  //입력값 변경될때마다 VALUE 변경s
  const onChangeAccount = (e: { target: { id: any; value: any; }; }) => {
    setAccount({
      ...account,
      [e.target.id]: e.target.value,
    });
  };

  //임시 계정 list
  const userList = [
    {
      id: "admin",
      pw: "admin",
      name: '관리자'
    }
  ];

  //ENTER 
  const handleOnKeyPress = (e: { key: string; }) => {
    if (e.key === 'Enter') {
      handleLogin(); 
    }
  };

  //LOGIN BTN ACTION
  const handleLogin = () => {

    //
    if(account.userId == ""){
      Swal.fire("","아이디를 입력해주세요.");
      return;
    } else if(account.userPw == ""){
      Swal.fire("","비밀번호를 입력해주세요.");
      return;
    }
    //
    
    let userChk = false;
    userList.forEach(function(item){
        if(item.id == account.userId && item.pw == account.userPw){
            userChk = true;
        } 
    });

    if(userChk){
        const expires = new Date();
        expires.setMinutes(expires.getMinutes() + 60);        //한시간 ++
        setCookie('login', account, { path: '/' ,expires : expires});

        //이동할 page
        history.push("/");
    } else {
      Swal.fire("","아이디, 비밀번호를 확인해주세요.");
    }
  
  };

  return (
    <div className="wrap">
      <div className="cLogin">
        <div className="loginArea">
          <div className="loginTop">
						   {/* <img src={logo} alt="로고 이미지"/> */}
						{/* <span>서울특별시 항공사진 AI</span> */}
					</div>

          <div className="loginBox">
							<div className="loginTit">로그인</div>
							<input type="text" className="cInputbox" placeholder="아이디" id = "userId" onChange={onChangeAccount} onKeyPress={handleOnKeyPress}/>
							<input type="password" className="cInputbox" placeholder="비밀번호" id = "userPw" onChange={onChangeAccount} onKeyPress={handleOnKeyPress}/>
							{/* <div className="loginFlex">
								<div className="cCheckbox">
									<input type="checkbox" id="checkboxItem00" checked>
									<label for="checkboxItem00">로그인 유지</label>
								</div>
								<a href="#" class="loginLink">비밀번호 찾기</a>
							</div>
							<input type="button" class="loginBtn" value="Login">
							<div class="loginFlex">
								<a href="#" class="loginLink">회원가입</a>
							</div> */}
              	<input type="button" className="loginBtn" value="Login" onClick={handleLogin}></input>
          </div>

        </div>
      </div>
    </div>
  );
};
