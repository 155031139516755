import axios from 'axios';

export const getFileList = async (dir?: string) => {
    return await axios.get(`/api/v1/file/manager`, {
        params: {
            dir
        }
    })
};

export const setUplod = async (form?: FormData) => {
    return await axios.post(`/api/v1/file/upload`, form, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });  
};

export const setMulitiUplod = async (form?: FormData) => {
    return await axios.post(`/api/v1/file/multiUpload`, form, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });  
};