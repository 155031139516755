import React, { useCallback } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import clsx from "clsx";
import { AppBar, Button, IconButton, Toolbar, Typography, makeStyles, ThemeProvider } from "@material-ui/core";
import { Menu as MenuIcon, Brightness4 as Brightness4Icon } from "@material-ui/icons";

import { currentThemeState } from "states/common";
import { drawerOpenState, drawerWidthState } from "states/layout";
import { isLayerSwipeState, isMapSplitState } from "states/map";
import { selectedProjectState } from "states/project";
import { ConfigContext } from "config";
import { useContext } from "react";

import logo from "images/seoul_ai_w.svg";

const useStyles = (drawerWidth: number) =>
    makeStyles((theme) => ({
        appBar: {
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            })
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            })
        },
        menuButton: {
            marginRight: theme.spacing(2)
        },
        hide: {
            display: "none"
        },
        barTitle: {
            flexGrow: 1
        },
        toolbar: {
            "&>button:not(:first-child)": {
                marginLeft: theme.spacing(1)
            }
        },
        logo: {
            flexGrow: 1,
            height: 32,
            backgroundImage: `url(${logo})`,
            backgroundSize: "auto 32px",
            backgroundRepeat: "no-repeat"
        }
    }));

const Header = (): React.ReactElement => {
    const config = useContext<any>(ConfigContext);
    const drawerWidth = useRecoilValue(drawerWidthState);
    const { detectionItem } = useRecoilValue(selectedProjectState);
    const classes = useStyles(drawerWidth)();

    const [open, setOpen] = useRecoilState(drawerOpenState);
    const [isLayerSwipe, setIsLayerSwipe] = useRecoilState(isLayerSwipeState);
    const [isMapSplit, setIsMapSplit] = useRecoilState(isMapSplitState);
    const [currentTheme, setCurrentTheme] = useRecoilState(currentThemeState);

    const handleClickMapTransform = (evt: React.MouseEvent) => {
        setIsLayerSwipe(!isLayerSwipe);
        setIsMapSplit(!isMapSplit);
    };

    const handleDrawerToggle = useCallback(() => {
        setOpen(!open);
    }, [setOpen, open]);

    const changeThemeHandler = (e: React.MouseEvent) => {
        setCurrentTheme(currentTheme === "light-theme-1" ? "dark-theme-1" : "light-theme-1");
    };

    return (
        <ThemeProvider theme={config.theme.toolbarTheme}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open
                })}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} edge="start" className={clsx(classes.menuButton, open && classes.hide)}>
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.logo}>{/* <img className={classes.logo} src={logo} /> */}</div>
                    {/* <Typography variant="h6" noWrap className={classes.barTitle}>
                        서울시 항공사진 AI
                    </Typography> */}
                    {detectionItem === "BuildingChange" && (
                        <Button variant="contained" color="secondary" onClick={handleClickMapTransform}>
                            맵 전환
                        </Button>
                    )}
                    <Button  variant="contained" color="secondary" onClick={changeThemeHandler} startIcon={<Brightness4Icon />}>
                        모드 전환
                    </Button>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
};

export default Header;
