import React, { MouseEventHandler, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    InputLabel,
    FormControlLabel,
    RadioGroup,
    FormLabel,
    Toolbar,
    Typography,
    NativeSelect,
    withStyles,
    Theme,
    createStyles,
    InputBase,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Tooltip,
    Paper,
    Select,
    MenuItem,
    Chip,
    styled
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactTooltip from "react-tooltip";
import { useLoadableContent, useRouter } from "hooks";
import { RedRadio, BlueRadio, GreenRadio, OrangeRadio } from "components/common/colorRadio";
import { codeInfoListSelector, forceReloadProjectListState } from "states/project";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";

import IFileInfo from "components/fileExplorer/IFileInfo";
import { FileExplorerDialog } from "components/fileExplorer";
import { red } from "@material-ui/core/colors";
import { RedButton } from "components/common/colorButton";
import Swal from "sweetalert2";

import * as _ from "lodash";
import { createProject, CreateProjectEntity, DetectionType } from "service/ProjectService";
import { isVariableDeclarationList } from "typescript";
import  "./swalCss/swalCss.scss";
import { setUplod , setMulitiUplod } from "service/FileManagerService";
import { any } from "prop-types";
interface IProjectRegisterDialogState {
    name: string;
    sggCde: string;
    detection?: DetectionType;
    year: number | undefined;
    base: {
        year: number | undefined;
        file: IFileInfo | undefined;
    };
    target: {
        year: number | undefined;
        file: IFileInfo | undefined;
    };
    myPcBase : {
        year: number | undefined;
        file: IFileInfo | undefined;
    };
    myPcBaseTarget : {
        year: number | undefined;
        file: IFileInfo | undefined;
    };
    shp: {
        // year: number | undefined;
        file: IFileInfo | undefined;
    };
    myPcShp: {
        // year: number | undefined;
        file: IFileInfo | undefined;
    };
}

interface IProjectRegisterDialogErrorState {
    isNameError: boolean;
    isSggCdeError: boolean;
    isDetectionError: boolean;
    isYearError: boolean;
}

interface selectedFileInfo {
    fileInfo: IFileInfo;
    label: string;
    isError: boolean;
    isPC : boolean;
    file : File | undefined;
}

const ProjectRegisterDialog = (): React.ReactElement => {
    const classes = useStyles();
    const { history } = useRouter();
    const [arrYear, setArrYear] = React.useState<number[]>([]);
    const [selectedFiles, setSelectedFiles] = React.useState<selectedFileInfo[]>([]);
    const [hasAnyError, setHasAnyError] = React.useState<boolean>(false);
    const setForceReloadProjectListState = useSetRecoilState(forceReloadProjectListState);
    const [state, setState] = React.useState<IProjectRegisterDialogState>({
        name: "",
        sggCde: "",
        detection: "BuildingChange",
        year: undefined,
        base: {
            year: undefined,
            file: undefined
        },
        target: {
            year: undefined,
            file: undefined
        },
        myPcBase : {
            year: undefined,
            file: undefined
        },
        myPcBaseTarget : {
            year: undefined,
            file: undefined
        },
        shp: {
            // year: undefined,
            file: undefined
        },
        myPcShp: {
            // year: number | undefined;
            file: undefined
        }
    });
    const [errorState, setErrorState] = React.useState<IProjectRegisterDialogErrorState>({
        isNameError: false,
        isSggCdeError: false,
        isDetectionError: false,
        isYearError: false
    });
    const codeInfoList = useRecoilValueLoadable(codeInfoListSelector);

    useEffect(() => {
        console.log("dialog open");
        const currentYear = new Date().getFullYear();
        let arrYear: number[] = [];
        for (let year = currentYear; year >= 2000; year--) {
            arrYear.push(year);
        }
        setArrYear(arrYear);
        return () => {
            console.log("dialog close");
        };
    }, []);

    useEffect(() => {
        isVaridated();
    }, [state, selectedFiles]);

    const handleClickCancelClose = () => {
        history.push("/");
    };

    const isVaridated = (): boolean => {
        let newState = { ...errorState };
        let hasLabelError = false;
        const chkStr = (str: any): boolean => {
            if (!str || str === undefined || (typeof str === "string" && str === "")) {
                return true;
            }
            return false;
        };

        newState.isNameError = chkStr(state?.name);
        newState.isSggCdeError = chkStr(state?.sggCde);
        newState.isYearError = state.detection !== "BuildingChange" && chkStr(state?.year);
        newState.isDetectionError = chkStr(state?.detection);

        if (state.detection !== "BuildingChange") {
            //Label check
            selectedFiles.map((item) => {
                if (item.label === undefined || item.label === null || item.label === "" || item.label.length < 2) {
                    item.isError = true;
                    hasLabelError = true;
                } else {
                    item.isError = false;
                }
            });
        }

        const _hasAnyError = newState.isNameError || newState.isSggCdeError || newState.isYearError || newState.isDetectionError || hasLabelError;
        setErrorState(newState);
        setHasAnyError(_hasAnyError);
        return !_hasAnyError;
    };

    const handleClickOkClose = async () => {
        const { name, sggCde, detection: dectection, year } = state;
        let payload: CreateProjectEntity;
        let hasError = false;
        if (dectection !== undefined && dectection !== "BuildingChange") {
            //isPc (내 PC 에서 올린 파일 확인)
            //form 에 담아 file upload..
            const fileList = new Array();

            selectedFiles.forEach((item) =>{
                if(item.isPC){
                    fileList.push(item.file);
                }
            })

            //파일이 있을경우
            if(fileList.length != 0){
                const formData = new FormData();
                fileList.forEach((item)=> {
                    formData.append('file', item);
                });

                var fileUplodList = undefined;
                if(fileList.length == 1){
                    fileUplodList =  await setUplod(formData);
                } else {
                    fileUplodList =  await setMulitiUplod(formData);
                }
                //파일이 정상적으로 올라갔으면 
                if(fileUplodList.data.status){
                    
                        fileUplodList.data.data.forEach((item: {uuidName: string; filePath: string; name: string; }) =>{
                            selectedFiles.forEach((info) =>{
                                if(item.name === info.fileInfo.name){
                                    info.fileInfo.path = item.filePath + item.uuidName;
                                }
                            })
                        });
                    }
            }


            payload = {
                title: name,
                sggCde: Number(sggCde),
                year: Number(year),
                detectionItem: dectection,
                dataPath: selectedFiles.map((item) => ({ img: item.fileInfo.path, layerName: item.label }))
            };

          
        } else {
            //건물 변화일시 --- 파일이 내 PC 일 경우 파일 업로드//
            let baseFilePath = undefined;
            let targetFilePath = undefined;
            let cadShpFilePath = undefined;

            // 파일 2개중 하나만 있는경우 return  //
            //기존 파일
            if(state.base.file != undefined || state.target.file != undefined){
                if(state.base.file == undefined || state.target.file == undefined){
                    Swal.fire("","파일을 확인해 주세요.");
                    return false;
                }

                if(state.myPcShp.file != undefined){
                    Swal.fire("","판독현황도 파일을 확인해주세요.");
                    return false;
                }
            }
            //내 pc
            if(state.myPcBase.file != undefined || state.myPcBaseTarget.file != undefined){

                if(state.myPcBase.file == undefined || state.myPcBaseTarget.file == undefined){
                    Swal.fire("","내 PC 파일을 확인해주세요.");
                    return false;
                }
                
                if(state.shp.file != undefined){
                    Swal.fire("","판독현황도 파일을 확인해주세요.");
                    return false;
                }
            }

            //shp 파일
            /////////////////////////////////////////

            // 1.myPC 파일이 있는지 확인
            if(state.myPcBase.file != undefined && state.myPcBaseTarget.file != undefined){
                 // 2.myPc 파일이 있을경우 파일 업로드

                 //보낼 form 
                 let formData = new FormData();
                 const baseFileList = new Array();
                 baseFileList.push(state.myPcBase.file);
                 baseFileList.push(state.myPcBaseTarget.file);
                 // 2-1 shp 파일 있으면
                 if(state.myPcShp.file != undefined){
                    baseFileList.push(state.myPcShp.file);
                 }

                 baseFileList.forEach((item)=> {
                    formData.append('file', item);
                });
                const fileList =  await setMulitiUplod(formData);

                //파일이 정상적으로 올라갔으면 
                if(fileList.data.status){

                    fileList.data.data.forEach((item: {uuidName: string;filePath: string; name: string; }) =>{

                        if(item.name == state.myPcBase.file?.name){
                            baseFilePath = item.filePath + item.uuidName;
                            console.log(baseFilePath);
                        }

                        if(item.name == state.myPcBaseTarget.file?.name){
                            targetFilePath = item.filePath + item.uuidName;
                        }

                        if(item.name == state.myPcShp.file?.name){
                            cadShpFilePath = item.filePath + item.uuidName;
                        } 
                        
                    })

                } else {
                    Swal.fire("","잠시후 다시 시도해주세요.");
                    return false;
                }

                payload = {
                    title: name,
                    sggCde: Number(sggCde),
                    year: Number(year),
                    detectionItem: "BuildingChange",
                    dataPath: {
                        [state.base.year as number]: baseFilePath,
                        [state.target.year as number]: targetFilePath,
                        cadShp: cadShpFilePath
                    }
                };
            } else {
                 // 3.file upload 후 file 경로            
                // ** 올라간 file 없을경우 기존 똑같이 실행**  
                payload = {
                    title: name,
                    sggCde: Number(sggCde),
                    year: Number(year),
                    detectionItem: "BuildingChange",
                    dataPath: {
                        [state.base.year as number]: state.base.file?.path,
                        [state.target.year as number]: state.target.file?.path,
                        cadShp: state.shp.file?.path
                    }
                };
            }
            //////////////////////////////////////////////////
        }
        if (!hasError && isVaridated()) {
            console.log("validated: Success");

            createProject(payload)
                .then((res) => {
                    if (res.status === 200) {
                        // Success
                        handleClickCancelClose();
                    }
                })
                .catch((_err) => {
                    // TODO error
                })
                .finally(() => {
                    setForceReloadProjectListState(Math.random());
                });
        } else {
            console.log("validated: fail");
        }
        console.log("payload:", payload);
    };

    const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dectection: DetectionType = (event.target as HTMLInputElement).value as DetectionType;
        setState({ ...state, detection: dectection });
    };

    const handleChangeSearchParams = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        if (event.target.name) {
            setState({
                ...state,
                [event.target.name]: event?.target?.value || ""
            });
            console.log("state:", state);
        }
    };

    const FormGroupYearNative = () => {
        return (
            <FormControl variant="outlined" className={classes.margin} style={{ flexGrow: 1 }}>
                <InputLabel id="year-select-filled-label">년도</InputLabel>
                <NativeSelect
                    name="year"
                    value={state.year}
                    error={errorState.isYearError}
                    onChange={(event) => {
                        setState({ ...state, year: Number(event.target.value) });
                    }}
                    input={<BootstrapInput />}
                >
                    <option value=""></option>
                    {arrYear.map((year: number, idx: number) => (
                        <option key={idx} value={year}>
                            {year}
                        </option>
                    ))}
                </NativeSelect>
            </FormControl>
        );
    };

    const ToolBarSelecteFiles = () => {
        const [open, setOpen] = React.useState<boolean>(false);
        const overLimitedMessageHandler = () => {
            //TODO 사진 제한 개수 초과 메시지
        };
        const deSelectFileHandler = (_e: any, idx: number) => {
            const newSelectedFiles = _.remove([...selectedFiles], (e) => e.fileInfo.path === selectedFiles[idx].fileInfo.path);
            setSelectedFiles(newSelectedFiles);
        };

        const myPcFileChange = (_val : any , type : String) =>{

            //파일 size chk
            const maxSize = 1024 * 1024 * 1024; //100MB
            const fileSize = _val.target.files[0].size;
            const file = _val.target.files[0];
    
            if(fileSize > maxSize){
                //alert("첨부파일 사이즈는 100MB 이내로 등록 가능합니다.");
                Swal.fire("","첨부파일 사이즈는 100MB 이내로 등록 가능합니다.");
                return false;
            }

            //파일 형식 chk 
            const fileName = _val.target.files[0].name;
            const fileType = fileName.slice(fileName.indexOf(".") + 1).toLowerCase();
            //파일 형식 뭐가 들어오야되는지 모르겟음... 있을시 추가..

            const fileInfo = {
                createDate : "",
                ext: fileType,
                isDirectory: false,
                name: fileName,
                path: _val.target.value,
                size: fileSize,
                updateDate: "",
                file : _val.target.files[0]
            }
            
         
            setSelectedFiles([...selectedFiles, { fileInfo: fileInfo, label: "", isError: false , isPC : true , file : file}]);
        }

        return (
            <>
                <Toolbar className={classes.toolbar} style={{ minHeight: 150, padding: "8px  24px" }}>
                    <FormControl component="fieldset" style={{ padding: "8px 20px" }}>
                        <FormLabel component="legend" style={{ fontSize: ".8rem" }}>
                            선택된 파일
                        </FormLabel>
                        <FormGroup>
                            <div style={{ padding: "8px 0", margin: "0 0 12px" }}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={(_e) => {
                                        selectedFiles.length < 2 ? setOpen(true) : overLimitedMessageHandler();
                                    }}
                                    startIcon={<AddIcon />}
                                >
                                    파일 선택(서버)
                                </Button>
                                &nbsp;&nbsp;
                                <Button  component="label" size="small" variant="contained" color="primary" startIcon={<AddIcon />}>    
                                     파일 선택(내 PC)        
                                    <VisuallyHiddenInput type="file" accept="image/*"   onChange={e => selectedFiles.length < 2 ? myPcFileChange(e,"target") : overLimitedMessageHandler()}/>
                                </Button>
                            </div>
                            {selectedFiles.length > 0 && (
                                <TableContainer component={Paper} variant="outlined">
                                    <Table size="small">
                                        <TableBody>
                                            {selectedFiles.map((elem: selectedFileInfo, idx: number) => (
                                                <TableRow key={idx}>
                                                    <TableCell>영상파일:</TableCell>
                                                    <TableCell>
                                                        <Tooltip title={elem.fileInfo.path}>
                                                            <span>{elem?.fileInfo?.name || ""}</span>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>레이어 명:</TableCell>
                                                    <TableCell>
                                                        <LabelField elem={elem} idx={idx} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <RedButton variant="contained" onClick={(e) => deSelectFileHandler(e, idx)} startIcon={<DeleteIcon />} size="small">
                                                            삭제
                                                        </RedButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </FormGroup>
                    </FormControl>
                    <FileExplorerDialog
                        open={open}
                        title="파일을 선택하세요."
                        onClose={(selectedFile: IFileInfo | null) => {
                            if (selectedFile) {
                                setSelectedFiles([...selectedFiles, { fileInfo: selectedFile, label: "", isError: false , isPC : false , file : undefined}]);
                            }
                            setOpen(false);
                        }}
                    />
                </Toolbar>
                <Typography variant="body1" className={classes.warningMessage}>
                    ※ 항공사진은 최대 2장까지 입력가능합니다.
                </Typography>
            </>
        );
    };
    const LabelField = ({ elem, idx }: { elem: selectedFileInfo; idx: number }): React.ReactElement => {
        const [state, setState] = React.useState<string>(elem.label);
        return (
            <TextField
                value={state}
                onChange={(e) => setState(e.target.value)}
                error={elem.isError}
                helperText={elem.isError && "레이블 명이 필요합니다."}
                onBlur={(_e) => {
                    const newSelectedFiles = [...selectedFiles];
                    const targetElem = newSelectedFiles[idx];

                    targetElem.label = state;
                    setSelectedFiles(newSelectedFiles);
                }}
                variant="outlined"
                size="small"
            />
        );
    };

 
    const ToolBarBuildingChangeInputs = () => {
        const classes = useStyles();
        const [openFileDialogBaseImage, setOpenFileDialogBaseImage] = React.useState<boolean>(false);        
        const [openFileDialogTargetImage, setOpenFileDialogTargetImage] = React.useState<boolean>(false);
        const [openFileDialogShpImage, setOpenFileDialogShpImage] = React.useState<boolean>(false);

        //내 PC
        const [openMyPcFileDialogBaseImage, setOpenMyPcFileDialogBaseImage] = React.useState<boolean>(false);
        const [openMyPcFileTargetDialogBaseImage, setOpenMyPcFileTargetDialogBaseImage] = React.useState<boolean>(false);
        const [openMyPcFileDialogShpBaseImage, setOpenMyPcFileDialogShpBaseImage] = React.useState<boolean>(false);

        const myPcBtnClick = (type : String) => {

            if(type == "standard"){
                setOpenMyPcFileDialogBaseImage(true);
            } else if (type == "target"){
                setOpenMyPcFileTargetDialogBaseImage(true);
            } else if(type == "shp"){
                setOpenMyPcFileDialogShpBaseImage(true);
            }

        }

        const myPcFileChange = (_val : any , type : String) =>{

            //파일 size chk
            const maxSize = 1024 * 1024 * 1024; //100MB
            const fileSize = _val.target.files[0].size;
            const file = _val.target.files[0];
    
            if(fileSize > maxSize){
                //alert("첨부파일 사이즈는 100MB 이내로 등록 가능합니다.");
                Swal.fire("","첨부파일 사이즈는 100MB 이내로 등록 가능합니다.");
                return false;
            }

            //파일 형식 chk 
            const fileName = _val.target.files[0].name;
            const fileType = fileName.slice(fileName.indexOf(".") + 1).toLowerCase();
            
            if(type != "shp"){                
                if(fileType != "tif"){                    
                    Swal.fire("",".tif 형식만 등록 가능합니다.");
                    return false;      
                };
            } else {
                if(fileType != "shp"){                    
                    Swal.fire("",".shp 형식만 등록 가능합니다.");
                    return false;      
                 };
            }

            //"" , 기존 영상 파일 , "target" : 분석 대상 파일 , "shp" : 판독현황도
            if(type == "") {
                //서버 PC 파일이 있을경우 file undefined 
                setState({ ...state, myPcBase: { ...state.myPcBase, file: file } , base: { ...state.base, file: undefined } });                
            } else if(type == "target"){    
                setState({ ...state, target: { ...state.target, file: undefined } ,myPcBaseTarget: { ...state.myPcBaseTarget, file: file } });                
            } else {
                setState({ ...state, shp: { ...state.shp, file: undefined } ,myPcShp: { ...state.myPcShp, file: file } });                
            }
        }



        return (
            <>
                <Toolbar className={classes.toolbar} style={{ height: 220 }}>
                    <FormControl component="fieldset" style={{ width: "100%", padding: "0 8px" }}>
                        <FormLabel component="legend" style={{ fontSize: ".8rem" }}>
                            입력 데이터 지정
                        </FormLabel>
                        <FormGroup style={{ justifyContent: "center", width: "100%", marginTop: 8 }}>
                            <TableContainer className={classes.detailTable} component={Paper} variant="outlined">
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell variant="head" width={160}>
                                                기준 영상 파일
                                            </TableCell>
                                            <TableCell width={40}>
                                                <FormGroupYear
                                                    arrYear={arrYear}
                                                    selctedYear={state.base.year || -1}
                                                    onChange={(e) => {
                                                        const newState = { ...state, base: { ...state.base, year: e.target.value as number } };
                                                        setState(newState);
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <AddButton onClick={(_e) => setOpenFileDialogBaseImage(true)} />
                                                {state.base.file && (
                                                    <Chip
                                                        label={state.base.file.name}
                                                        onDelete={(_e) => {
                                                            setState({ ...state, base: { ...state.base, file: undefined } });
                                                        }}
                                                        color="secondary"
                                                        style={{ margin: "0 8px" }}
                                                    />
                                                )}
                                                <FileExplorerDialog
                                                    open={openFileDialogBaseImage}
                                                    title="파일을 선택하세요."
                                                    onClose={(selectedFile: IFileInfo | null) => {
                                                        if (selectedFile) {
                                                            const newState = { ...state, base: { ...state.base, file: selectedFile }, myPcBase: { ...state.myPcBase, file: undefined } };
                                                            setState(newState);
                                                        }
                                                        setOpenFileDialogBaseImage(false);
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                {/* <AddMyPcButton onClick={(_e) => setOpenMyPcFileDialogBaseImage(true)} /> */}
                                                <Button  component="label"  onClick={() =>myPcBtnClick("standard")} size="small" variant="contained" color="primary" startIcon={<AddIcon />}>    
                                                    파일 선택(내 PC)        
                                                    <VisuallyHiddenInput type="file" accept="image/*"   onChange={e => myPcFileChange(e,"")}/>
                                                </Button>
                                                
                                                {state.myPcBase.file && (
                                                    <Chip
                                                        label={state.myPcBase.file?.name}
                                                        onDelete={(_e) => {
                                                            setState({ ...state, myPcBase: { ...state.myPcBase, file: undefined } });
                                                            setOpenMyPcFileDialogBaseImage(false);
                                                        }}
                                                        color="secondary"
                                                        style={{ margin: "0 8px" }}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">분석 대상 파일</TableCell>
                                            <TableCell>
                                                <FormGroupYear
                                                    arrYear={arrYear}
                                                    selctedYear={state.target.year || -1}
                                                    onChange={(e) => {
                                                        const newState = { ...state, target: { ...state.target, year: e.target.value as number } };
                                                        setState(newState);
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <AddButton onClick={(_e) => setOpenFileDialogTargetImage(true)} />
                                                {state.target.file && (
                                                    <Chip
                                                        label={state.target.file.name}
                                                        onDelete={(_e) => {
                                                            setState({ ...state, target: { ...state.target, file: undefined } });
                                                        }}
                                                        color="secondary"
                                                        style={{ margin: "0 8px" }}
                                                    />
                                                )}
                                                <FileExplorerDialog
                                                    open={openFileDialogTargetImage}
                                                    title="파일을 선택하세요."
                                                    onClose={(selectedFile: IFileInfo | null) => {
                                                        if (selectedFile) {
                                                            const newState = { ...state, target: { ...state.target, file: selectedFile },myPcBaseTarget: { ...state.myPcBaseTarget, file: undefined } };
                                                            setState(newState);
                                                        }
                                                        setOpenFileDialogTargetImage(false);
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                {/* <AddMyPcButton onClick={(_e) => setOpenMyPcFileDialogBaseImage(true)} /> */}
                                                <Button  component="label"  onClick={() =>myPcBtnClick("target")} size="small" variant="contained" color="primary" startIcon={<AddIcon />}>    
                                                    파일 선택(내 PC)        
                                                    <VisuallyHiddenInput type="file" accept="image/*"   onChange={e => myPcFileChange(e,"target")}/>
                                                </Button>
                                                
                                                {state.myPcBaseTarget.file && (
                                                    <Chip
                                                        label={state.myPcBaseTarget.file?.name}
                                                        onDelete={(_e) => {
                                                            setState({ ...state, myPcBaseTarget: { ...state.myPcBaseTarget, file: undefined } });
                                                            setOpenMyPcFileDialogBaseImage(false);
                                                        }}
                                                        color="secondary"
                                                        style={{ margin: "0 8px" }}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">
                                                판독현황도
                                                <Typography variant="caption">(선택사항)</Typography>
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell align="left">
                                                <AddButton onClick={(_e) => setOpenFileDialogShpImage(true)} />
                                                {state.shp.file && (
                                                    <Chip
                                                        label={state.shp.file.name}
                                                        onDelete={(_e) => {
                                                            setState({ ...state, shp: { ...state.shp, file: undefined } });
                                                        }}
                                                        color="secondary"
                                                        style={{ margin: "0 8px" }}
                                                    />
                                                )}
                                                <FileExplorerDialog
                                                    open={openFileDialogShpImage}
                                                    title="파일을 선택하세요."
                                                    onClose={(selectedFile: IFileInfo | null) => {
                                                        if (selectedFile) {
                                                            const newState = { ...state, shp: { ...state.shp, file: selectedFile } , myPcShp: { ...state.myPcShp, file: undefined }};
                                                            setState(newState);
                                                        }
                                                        setOpenFileDialogShpImage(false);
                                                    }}
                                                />
                                            </TableCell>

                                            <TableCell align="left">
                                                {/* <AddMyPcButton onClick={(_e) => setOpenMyPcFileDialogBaseImage(true)} /> */}
                                                <Button  component="label"  onClick={() =>myPcBtnClick("shp")} size="small" variant="contained" color="primary" startIcon={<AddIcon />}>    
                                                    파일 선택(내 PC)        
                                                    <VisuallyHiddenInput type="file" accept="*"   onChange={e => myPcFileChange(e,"shp")}/>
                                                </Button>
                                                
                                                {state.myPcShp.file && (
                                                    <Chip
                                                        label={state.myPcShp.file?.name}
                                                        onDelete={(_e) => {
                                                            setState({ ...state, myPcShp: { ...state.myPcShp, file: undefined } });
                                                            setOpenMyPcFileDialogShpBaseImage(false);
                                                        }}
                                                        color="secondary"
                                                        style={{ margin: "0 8px" }}
                                                    />
                                                )}
                                            </TableCell>                                            
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </FormGroup>
                    </FormControl>
                </Toolbar>
                <Typography variant="body1" className={classes.warningMessage}>
                    ※ 건물탐지변화 분석은 2장의 항공사진이 필요합니다.
                </Typography>
            </>
        );
    };

    return (
        <Dialog open={true} onClose={handleClickCancelClose} aria-labelledby="form-dialog-title" maxWidth="md">
            <DialogTitle id="form-dialog-title">프로젝트 생성</DialogTitle>
            <DialogContent style={{ minWidth: 840, padding: 0 }}>
                {hasAnyError && <Alert severity="error">유효하지 않는 항목이 존재합니다. <strong>확인하세요.</strong></Alert>}
                <ReactTooltip id="tipForName" effect="solid" event="focusin" eventOff="focusout">
                    <Typography variant="body1">프로젝트 제목은 탐지를 수행작업을 구분하기위한 이름입니다.</Typography>
                    <Typography variant="body2"> 예시) 종로구 2021년 건물변화 탐지</Typography>
                </ReactTooltip>

                <Toolbar>
                    <FormControl variant="outlined" className={classes.margin} style={{ flexGrow: 4 }}>
                        <InputLabel id="sggCde-select-filled-label">제목</InputLabel>
                        <BootstrapInput
                            data-tip
                            data-for="tipForName"
                            type="name"
                            error={errorState.isNameError}
                            value={state?.name || ""}
                            onChange={(e) => {
                                setState({ ...state, name: e.target.value });
                            }}
                        />
                    </FormControl>

                    <FormControl variant="outlined" className={classes.margin} style={{ flexGrow: 2 }}>
                        <InputLabel id="sggCde-select-filled-label">시군구</InputLabel>
                        <NativeSelect id="sggCde" name="sggCde" value={state.sggCde} error={errorState.isSggCdeError} onChange={handleChangeSearchParams} input={<BootstrapInput />}>
                            <option value=""></option>
                            {useLoadableContent(codeInfoList).map((codeInfo: any, idx: number) => (
                                <option key={idx} value={codeInfo.codeId}>
                                    {codeInfo.codeName}
                                </option>
                            ))}
                        </NativeSelect>
                    </FormControl>
                    {state.detection !== "BuildingChange" && <FormGroupYearNative />}
                </Toolbar>

                <Toolbar>
                    <FormControl component="fieldset" style={{ padding: "0 20px" }}>
                        <FormLabel component="legend" style={{ fontSize: ".75rem" }}>
                            탐지항목 선택
                        </FormLabel>
                        <RadioGroup row value={state.detection} onChange={handleCategoryChange}>
                            <FormControlLabel value="BuildingChange" control={<RedRadio />} label="건물변화" />
                            <FormControlLabel value="Building" control={<BlueRadio />} label="건물" />
                            <FormControlLabel value="Vegetation" control={<GreenRadio />} label="녹지" />
                            <FormControlLabel value="SolarP" control={<OrangeRadio />} label="태양광" />
                        </RadioGroup>
                    </FormControl>
                </Toolbar>

                {state.detection !== "BuildingChange" ? <ToolBarSelecteFiles /> : <ToolBarBuildingChangeInputs />}

                {/* <FileExplorer /> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClickCancelClose} color="primary">
                    취소
                </Button>
                <Button variant="contained" onClick={handleClickOkClose} color="primary">
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProjectRegisterDialog;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1)
        },
        chipsArea: {
            display: "flex",
            padding: theme.spacing(1),
            "& > *": {
                margin: theme.spacing(0.5)
            }
        },
        detailTable: {
            "& .MuiTableRow-root": { height: theme.spacing(8) }
        },
        toolbar: {
            // borderBottom: "1px solid #eee",
            width: "100%"
        },
        warningMessage: { padding: "8px 36px", color: red[800] }
    })
);

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "label + &": {
                marginTop: theme.spacing(1)
            },
            "&.Mui-error input,&.Mui-error select": {
                borderRadius: 4,
                borderColor: "#ff3333",
                boxShadow: "0 0 0 0.2rem rgba(255,32,32,.65)"
            }
        },
        input: {
            borderRadius: 4,
            position: "relative",
            backgroundColor: theme.palette.background.paper,
            border: "1px solid",
            boderColor: theme.palette.divider,
            fontSize: 16,
            padding: "10px 26px 10px 12px",
            transition: theme.transitions.create(["border-color", "box-shadow"]),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                "pretendard",
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"'
            ].join(","),
            "&:focus": {
                borderRadius: 4,
                borderColor: "#80bdff",
                boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
            }
        }
    })
)(InputBase);

interface FormGroupYearProps {
    selctedYear: number | undefined;
    onChange: (event: React.ChangeEvent<{ value: unknown }>) => void | undefined;
    arrYear: number[] | undefined;
}

const FormGroupYear = ({ selctedYear, onChange, arrYear = [] }: FormGroupYearProps) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            margin: {
                margin: theme.spacing(1),
                transition: "all 0.2s"
            }
        })
    );

    const classes = useStyles();
    const rand = Math.random();
    const labelId = "select_label_" + rand;
    return (
        <FormControl className={classes.margin}>
            <Select labelId={labelId} value={selctedYear || -1} onChange={onChange}>
                <MenuItem value={-1}>년도</MenuItem>
                {arrYear.map((year: number, idx: number) => (
                    <MenuItem key={idx} value={year}>
                        {year}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

interface AddButtonProps {
    onClick?: MouseEventHandler | undefined;
}
const AddButton = ({ onClick }: AddButtonProps) => (
    <Button onClick={onClick} size="small" variant="contained" color="primary" startIcon={<AddIcon />}>
        파일 선택(서버)
    </Button>
);
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });



const AddMyPcButton = ({ onClick }: AddButtonProps) => (
    <Button  component="label"  onClick={onClick} size="small" variant="contained" color="primary" startIcon={<AddIcon />}>    
        파일 선택(내 PC)        
    </Button>
);
