import axios from "axios";
import { saveAs } from "file-saver";

export interface SearchParamsInterface {
    sggCde?: string;
    year?: number;
    title?: string;
}

export type DetectionType = "BuildingChange" | "Building" | "Vegetation" | "SolarP";

export interface CreateProjectEntity {
    title: string;
    year?: number;
    sggCde: number;
    detectionItem: DetectionType;
    dataPath: { [year: number]: string | undefined; cadShp: string|undefined } | { img: string; layerName: string }[];
}
export interface ProjectStats {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
    }[];
}

export const findProjects = async (params: SearchParamsInterface) => {
    return await axios.get(`/api/v1/projects`, {
        params
    });
};

export const findProject = async (projectId: string, action: string = "info") => {
    return await axios.get(`/api/v1/projects/${projectId}/${action}`, {});
};

export const exportDetectionResult = async (projectId: string, fileName: string = "export.zip") => {
    const { data } = await axios.get(`/api/v1/projects/${projectId}/file`, { responseType: "blob" });
    console.log(data);
    saveAs(data, fileName);
};

export const createProject = async (payload: CreateProjectEntity) => {
    return await axios.post(`/api/v1/projects`, payload);
};

export const updateProject = async (projectId: string, data: { title: string }) => {
    return await axios.put(`/api/v1/projects/${projectId}`, data);
};

export const deleteProject = async (projectId: string) => {
    return await axios.delete(`/api/v1/projects`, {
        params: {
            projectId
        }
    });
};