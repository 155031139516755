import Overlay from 'ol/Overlay.js';
import { FeatureLike } from "ol/Feature";
import './layerCss/layerInfo.scss';

const LayerInfoBox = (feature : FeatureLike , InnoMap : import('libs/gis').InnoMap) => {
 
    const container = document.getElementById('popup') as HTMLInputElement;
    const content = document.getElementById('popup-content') as HTMLInputElement;
    const closer = document.getElementById('popup-closer') as HTMLInputElement;


    const overlay = new Overlay({
         id : "popup",
         element: container,
         autoPan: {
           animation: {
             duration: 250,
           },
         },
       });

    closer.onclick = function(){
        overlay.setPosition(undefined);
        closer.blur();
        return false;
    }
  
    InnoMap.addOverlay(overlay);
    //@ts-ignore
    const [ minX, minY, maxX, maxY ] = feature.getGeometry().getExtent();
    
    //@ts-ignore
    const type = feature.values_.type;
    let infoType = "";
    if(type == "A"){
      infoType = "신축";
    } else if(type == "B"){
      infoType = "철거";
    } else if(type == "C"){
      infoType = "갱신";
    } else {
      infoType = "색변화";
    }
    

    let contentHtml = "";
    
    contentHtml += `<div class='ol-popup-title'>상세정보</div>`;
    contentHtml += `<table class='ol-popup-table'>`;
    contentHtml += ` <colgroup>`;
    contentHtml += `  <col width='80'>`;
    contentHtml += `  <col width='*'>`;
    contentHtml += ` </colgroup>`;
    contentHtml += ` <tbody>`;
    contentHtml += `  <tr>`;
    //@ts-ignore
    contentHtml += `    <th>ID</th><td>${feature.values_.layer_id}</td>`;
    contentHtml += `  </tr>`;
    contentHtml += `  <tr>`;
    //ts-ignore
    contentHtml += `    <th>유형</th><td>${infoType}</td>`;
    contentHtml += `  </tr>`;    
    contentHtml += `  <tr>`;
    //@ts-ignore
    contentHtml += `    <th>추론신뢰도</th><td>${feature.values_.confidence}%</td>`;
    contentHtml += `  </tr>`;
    contentHtml += `  <tr>`;
    contentHtml += `    <th>X 좌표</th><td>${(maxX + minX) / 2}</td>`;
    contentHtml += `  </tr>`;
    contentHtml += `  <tr>`;
    contentHtml += `    <th>Y 좌표</th><td>${(maxY + minY) / 2}</td>`;
    contentHtml += `  </tr>`;
    contentHtml += `  <tr>`;
    //@ts-ignore
    contentHtml += `    <th>면적(㎡)</th><td>${feature.values_.detection_area}</td>`;
    contentHtml += `  </tr>`;
    contentHtml += ` </tbody>`;
    contentHtml += `</table>`;

    content.innerHTML = contentHtml;
    
    overlay.setPosition([ (maxX + minX) / 2, (maxY + minY) / 2 ]);
};


export default LayerInfoBox;
