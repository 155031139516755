import { Tile as TileLayer, Layer, Vector as VectorLayer } from "ol/layer";
import { XYZ, Vector as VectorSource } from 'ol/source';
import TileGrid from 'ol/tilegrid/TileGrid';
import { get as getProjection } from "ol/proj";
import { all as allStrategy } from 'ol/loadingstrategy';
import { GeoJSON } from 'ol/format';
import { Fill, Stroke, Style, Text } from 'ol/style';

import axios from 'axios';

interface BackgroundMapConfigTypes {
    [key: string]: {
        layers: {
            [key: string]: {
                name: string;
                url: string;
                visible: boolean;
            }
        },
        [key: string]: any
    }
}

/**
 * 배경지도 목록 (구조 맞춰줘야함..)
 * @type {{VWorld: {name: string, attribution: string, layers: {Gray: {visible: boolean, name: string, url: string}, Hybrid: {visible: boolean, name: string, url: string}, Satellite: {visible: boolean, name: string, url: string}, Midnight: {visible: boolean, name: string, url: string}, Base: {visible: boolean, name: string, url: string}}, korName: string}}}
 */
const backgroundMapConfig: BackgroundMapConfigTypes = {
    // VWorld: {
    //     name : "vworld",
    //     korName : "브이월드",
    //     attribution: `© <a href="http://www.vworld.kr/dev/v4dv_apicla_a001.do" target="_blank">VWorld contributors</a>`,
    //     crsCode : "EPSG:3857",
    //     // extent : [13756219.106426602, 3860987.1727408236, 14666125.491133342, 4863840.983842337],
    //     // resolutions : [2445.9849051256406, 1222.9924525628203, 611.4962262814101, 305.7481131407051, 152.87405657035254, 76.43702828517627, 38.218514142588134, 19.109257071294067, 9.554628535647034, 4.777314267823517, 2.3886571339117584, 1.1943285669558792, 0.5971642834779396, 0.2985821417389698],
    //     layers: {
    //         Base : {
    //             name : "기본지도",
    //             url : `http://api.vworld.kr/req/wmts/1.0.0/9232CC65-CEAA-3E02-ACEB-98E9A27D36CA/Base/{z}/{y}/{x}.png`,
    //             // url : ` https://xdworld.vworld.kr/2d/Base/service/{z}/{x}/{y}.png`,
    //             visible : true
    //         },
    //         Gray : {
    //             name : "흑백지도",
    //             url : `http://api.vworld.kr/req/wmts/1.0.0/9232CC65-CEAA-3E02-ACEB-98E9A27D36CA/gray/{z}/{y}/{x}.png`,
    //             visible : false
    //         },
    //         Satellite : {
    //             name : "위성지도",
    //             url : `http://api.vworld.kr/req/wmts/1.0.0/9232CC65-CEAA-3E02-ACEB-98E9A27D36CA/Satellite/{z}/{y}/{x}.jpeg`,
    //             visible : false
    //         },
    //         Midnight : {
    //             name : "야간지도",
    //             url : `http://api.vworld.kr/req/wmts/1.0.0/9232CC65-CEAA-3E02-ACEB-98E9A27D36CA/midnight/{z}/{y}/{x}.png`,
    //             visible : false
    //         },
    //         Hybrid : {
    //             name : "하이브리드",
    //             url : `http://api.vworld.kr/req/wmts/1.0.0/9232CC65-CEAA-3E02-ACEB-98E9A27D36CA/Hybrid/{z}/{y}/{x}.png`,
    //             visible : false
    //         }
    //     }
    // },
    // Dawul: {
    //     name : "dawul",
    //     korName : "서울시스마트맵",
    //     attribution: `© <a href="https://map.seoul.go.kr/smgis2/" target="_blank">스마트서울맵</a>`,
    //     crsCode : "EPSG:5179",
    //     extent: [910966, 1919499, 997207, 1977678],
    //     origin : [90112, 1192896],
    //     resolutions : [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25, 0.125, 0.0625],
    //     layers: {
    //         Base : {
    //             name : "기본지도",
    //             url : `https://map.seoul.go.kr/smgis2/mapsvr/getTileMap?key=ea061e94268b45c0b4ac9859a7b591af&URL=http://smjd3:7070/MapAppServer/Service?timg=dawul_kor_normal`,
    //             // url : `https://map.seoul.go.kr/smgis/apps/mapsvr.do?cmd=getTileMap?key=8fde3d490bee4ae283366b1108e98485&URL=https://map.seoul.go.kr/MapAppServer/Service?timg=dawul_kor_normal`,
    //             // url : `https://map.seoul.go.kr/smgis/apps/mapsvr.do?cmd=getTileMap?key=8fde3d490bee4ae283366b1108e98485&URL=https://map.seoul.go.kr/MapAppServer/Service?timg=dawul_kor_normal`,
    //             // url : `https://map.seoul.go.kr/smgis/apps/mapsvr.do?cmd=getTileMap&key=8fde3d490bee4ae283366b1108e98485&URL=http://98.33.2.93:7070/MapAppServer/Service?timg=dawul_kor_normal`,
    //             // url : `https://map.seoul.go.kr/smgis/apps/mapsvr.do?cmd=getTileMap&key=8fde3d490bee4ae283366b1108e98485&URL=http://smjd3:7070/MapAppServer/Service?timg=dawul_kor_normal`,
    //             visible : true
    //         },
    //         Satellite : {
    //             name : "위성지도",
    //             url : `https://map.seoul.go.kr/smgis2/mapsvr/getTileMap?key=ea061e94268b45c0b4ac9859a7b591af&URL=http://smjd3:7070/MapAppServer/Service?timg=dawul_air`,
    //             visible : false
    //         },
    //         Hybrid : {
    //             name : "하이브리드",
    //             url : `https://map.seoul.go.kr/smgis2/mapsvr/getTileMap?key=ea061e94268b45c0b4ac9859a7b591af&URL=http://smjd3:7070/MapAppServer/Service?timg=dawul_kor_air`,
    //             visible : false
    //         },
    //     }
    // },
    Sgg: {
        name : "sgg",
        korName : "시군구경계",
        attribution: ``,
        crsCode : "EPSG:5179",
        extent: [910966, 1919499, 997207, 1977678],
        origin : [90112, 1192896],
        resolutions : [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25, 0.125, 0.0625],
        layers: {
            Base : {
                name : "기본지도",
                url : `/api/v1/land/sggCde`,
                visible : true
            },
        }
    },
};

class InnoBackgroundMap {
    private readonly _layers: { [key: string]: Layer };
    private readonly _initVisibleName: string;

    /**
     *
     * @param {string} initVisibleName
     */
    constructor(initVisibleName: string = '') {
        this._layers = {};
        // this._layers = [];
        this._initVisibleName = initVisibleName;
        this.init();
    }

    /**
     * 객체 생성 시 초기화 함수
     * @private
     */
    private init() {
        Object.entries(backgroundMapConfig).forEach(([kind, config]) => {
            const attributions = `© <a href="https://www.innopam.com/" target="_blank">InnoPAM</a> ${config.attribution}`;

            Object.entries(config.layers).forEach(([layerKind, info]) => {
                let layerProperties = {};
                const layerName = `${kind}${layerKind}`;
                if (kind === 'Sgg') {
                    const source = new VectorSource({
                        loader: async (extent) => {
                            source.clear(true);
                            const [minx, miny, maxx, maxy] = extent;
                            const { data } = await axios.get(`${info.url}`, {
                                params: {
                                    minx, miny, maxx, maxy
                                }
                            });

                            const fs = new GeoJSON().readFeatures(data);
                            source.addFeatures(fs);
                        },
                        // strategy: bboxStrategy
                        strategy: allStrategy
                    });
                    layerProperties = {
                        kind,
                        name : layerName,
                        source,
                        visible : info.visible,
                        style: (feature: import('ol/Feature').FeatureLike) => new Style({
                            fill: new Fill({
                                color: 'rgba(255,255,0,0.1)'
                            }),
                            stroke: new Stroke({
                                color: 'rgba(0,0,0,1)',
                                width: 1
                            }),
                            text: new Text({
                                font: '12px Calibri,sans-serif',
                                text: feature.get(''),
                                fill: new Fill({
                                    color: 'rgba(0,0,0,0.4)',
                                }),
                                stroke: new Stroke({
                                    color: 'rgba(0,0,0,0.8)',
                                    width: 0.5,
                                }),
                            })
                        })
                    };

                    this._layers[layerName] = new VectorLayer(layerProperties);
                } else {
                    if (kind === 'Dawul') {
                        const tileGrid = new TileGrid({
                            origin : config.origin,
                            resolutions : config.resolutions,
                        });

                        layerProperties = {
                            kind,
                            name : layerName,
                            source : new XYZ({
                                projection: getProjection(config.crsCode),
                                attributions: attributions,
                                maxZoom: 13,
                                tileGrid,
                                tileUrlFunction: (tileCoord, pixelRatio, projection) => {
                                    if (!tileCoord) return undefined;
                                    const tileExtent = tileGrid.getTileCoordExtent(tileCoord);

                                    let [cZ, cX, cY] = tileCoord;

                                    let z, x, y;
                                    z = cZ + 1;
                                    x = cX;
                                    let i = Math.round((tileExtent[1] - tileExtent[3]) / (config.resolutions[z] * 256));
                                    y = Math.floor(i - cY + 1);

                                    const xIndex = Math.floor(x / 50);
                                    const yIndex = Math.floor(y / 50);

                                    return `${info.url}/${z}/${xIndex}/${yIndex}/${x}_${y}.png`;
                                }
                            }),
                            visible : info.visible,
                        };
                    } else {
                        layerProperties = {
                            kind,
                            name : layerName,
                            source : new XYZ({
                                projection: getProjection(config.crsCode),
                                attributions: attributions,
                                url : info.url,
                            }),
                            visible : info.visible,
                        }
                    }

                    this._layers[layerName] = new TileLayer(layerProperties);
                }

            });
        });
    }

    /**
     * 초기화된 배경지도 레이어 목록
     * @returns {{layerName: VectorTileLayer | TileLayer}|*}
     */
    getLayers() {
        const initVisibleLayer = this._layers[this._initVisibleName];
        if (initVisibleLayer) {
            Object.values(this._layers).forEach(_layer  => {
                _layer.setVisible(false);
            });
            initVisibleLayer.setVisible(true);
        }
        return this._layers;
    }

    getBackgroundInfo() {
        const layer: any = Object.values(this._layers).find(_layer => _layer.getVisible());
        const kind = layer.get('kind');

        const { crsCode, resolutions } = backgroundMapConfig[kind];
        return { crsCode, resolutions };
    }

    /**
     * 레이어명에 따른 배경지도 변경
     * @param kind 배경지도 레이어 명
     */
    changeBackground(kind: string) {
        Object.values(this._layers).forEach(layer => {
            layer.setVisible(false);
        });

        this._layers[kind].setVisible(true);
        if (kind === 'VWorldHybrid') {
            this._layers['VWorldSatellite'].setVisible(true);
        }
    }


}

export default InnoBackgroundMap;