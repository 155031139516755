import { makeStyles,  Typography, ThemeProvider, Select, FormControl, MenuItem, Card } from "@material-ui/core"
import { ConfigContext } from "config"
import React, { useContext, useState } from "react"
import { Loadable, useRecoilValue, useRecoilValueLoadable } from "recoil"
import { isMapSplitState, layerListSelector } from 'states/map'
import { SelectedProject, selectedProjectState } from "states/project"
import MapContext from "Map/MapContext"
import { Style } from "ol/style"
import { InnoMap } from "libs/gis"
const Inference = (): React.ReactElement => {
    
    const classes = useStyles();
    const config = useContext<any>(ConfigContext);
    const [formData, setFormData] = useState<any>({});
   
    const { innoMap, innoLayer } = useContext(MapContext);
    const handleChangeFormData = (evt: any) => {
        setFormData({
            ...formData,
            [evt.target.name]: evt.target.value
        })
        featureFillter(evt.target.value);       
        
    }

    
    const { title } = useRecoilValue<SelectedProject>(selectedProjectState)
    
    const layerList = useRecoilValueLoadable(layerListSelector)
    const featureFillter = (value : String) => {

        const leftLayer = layerList.getValue().leftLayers
        const rightLayer = layerList.getValue().rightLayers
        
       leftLayer.forEach((item: {layerId: number ,layerType: string}) => {
            if(item.layerType == "detection"){

                const leftFeatureList =  innoMap.map.getLayers().getArray().filter((layer: { get: (arg0: string) => number }) => layer.get('id') === item.layerId)[0].getSource().getFeatures()

                leftFeatureList.forEach((item: { values_: { inference: any } ,setStyle: (arg0: Style) => void }) => {
                    const inferenceVal = item.values_.inference
                    if(value == inferenceVal || value == '0'){
                        item.setStyle(innoLayer.getAnalysisStyle(item))
                    } else {
                        item.setStyle(new Style({}))
                    }
                })
            }
       })

       if(rightLayer !=undefined){

        
        rightLayer.forEach((item: {layerId: number ,layerType: string}) => {
            if(item.layerType == "detection"){

                
                // const rightFeatureList = 
                // rightFeatureList.forEach((item: { values_: { inference: any } ,setStyle: (arg0: Style) => void }) => {
                //     const inferenceVal = item.values_.inference
                //     if(value == inferenceVal || value == '0'){
                //         item.setStyle(innoLayer.getAnalysisStyle(item))
                //     } else {
                //         item.setStyle(new Style({}))
                //     }
                // })
            }
       })
       }

    }

    return (
        <ThemeProvider theme={config.theme.toolbarTheme}>
            {title && title.length > 1 && (
                <Card className={classes.root}>
                    <Typography variant="h5">추론 신뢰도(%)</Typography>
                        <FormControl variant="outlined" fullWidth size="small">
                                    <Select
                                        id="isInference"
                                        name="isInference"
                                        style={{zIndex:10000}}
                                        value={Number(formData?.isInference ?? 0)}
                                        onChange={handleChangeFormData}>
                                        <MenuItem value={0}>
                                            전체
                                        </MenuItem>
                                        <MenuItem value={100}>
                                            100%
                                        </MenuItem>
                                        <MenuItem value={90}>
                                            90%
                                        </MenuItem>
                                        <MenuItem value={80}>
                                            80%
                                        </MenuItem>
                                        <MenuItem value={70}>
                                            70%
                                        </MenuItem>
                                        <MenuItem value={60}>
                                            60%
                                        </MenuItem>
                                        <MenuItem value={50}>
                                            50%
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                </Card>
            )}
        </ThemeProvider>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: 100,
        left: 0,
        zIndex: 999,
        maxWidth: 400,
        margin: theme.spacing(2),
        padding: theme.spacing(1, 2),
        userSelect: "none"
    }
}))
export default Inference


