import React, {useCallback, useContext} from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState, useRecoilStateLoadable } from 'recoil';
import {
    Paper,
    ButtonGroup,
    Button,
} from '@material-ui/core';
import OpinionRegisterDialog from './OpinionRegisterDialog';
import { useSnackbar } from 'notistack';
import { opinionListSelector, selectedDataIdState } from 'states/opinion';
import { selectedProjectState } from 'states/project';
import { confirmState } from 'states/common';
import { InnoDraggableModal, InnoTable } from 'components/common';
import { useLoadableContent } from 'hooks';
import { deleteOpinion } from 'service/OpinionService';
import MapContext from 'Map/MapContext';

const OpinionResult = (): React.ReactElement => {
    const { innoMap } = useContext(MapContext);
    const { enqueueSnackbar } = useSnackbar();
    const [opinionList, reload] = useRecoilStateLoadable(opinionListSelector);
    const [confirm, setConfirm] = useRecoilState(confirmState);
    const setSelectedDataId = useSetRecoilState(selectedDataIdState);

    const handleClickMove = (geom: any) => () => {
        innoMap.moveByGeoJson(geom);
    };

    const handleClickEditOpen = useCallback((dataId: number) => () => {
        setSelectedDataId(dataId);
    }, []);

    const handleClickDelete = (projectId: string, opinionIdx: number) => () => {
        setConfirm({
            open: true,
            title: `해당 의견을 삭제하시겠습니까?`,
            handleCancel: (info: any) => (evt: any) => {
                setConfirm({
                    ...confirm,
                    ...info,
                    open: false,
                });
            },
            handleOk: (info: any) => async (evt: any) => {
                try {
                    await deleteOpinion(projectId, opinionIdx);
                    enqueueSnackbar('정상적으로 삭제되었습니다.', {
                        variant: 'success',
                    });
                    reload(0);
                } catch (e) {
                    enqueueSnackbar('삭제 중 에러발생. 다시 시도하여주십시오.', {
                        variant: 'error',
                    });
                } finally {
                    setConfirm({
                        ...confirm,
                        ...info,
                        open: false,
                    });
                }
            },
        });
    };

    const columns = [
        {
            id: 'opinionIdx',
            label: '번호',
            maxWidth: 74,
            align: 'center',
        },
        {
            id: 'isDetection',
            label: '탐지결과',
            maxWidth: 106,
            align: 'center',
            format: (value: boolean, row: any) => value ? '탐지 성공' : '오탐지',
        },
        {
            id: 'comment',
            label: '의견',
            maxWidth: 150,
            align: 'center',
        },
        {
            id: '',
            label: '',
            width: 174,
            align: 'center',
            format: (value: any, row: any) => {
                return (
                    <ButtonGroup variant="contained" color="primary" size="small">
                        <Button
                            onClick={handleClickMove(row.geom)}
                        >
                            이동
                        </Button>
                        <Button
                            onClick={handleClickEditOpen(row.dataId)}
                        >
                            수정
                        </Button>
                        <Button
                            onClick={handleClickDelete(row.projectId, row.opinionIdx)}
                        >
                            삭제
                        </Button>
                    </ButtonGroup>
                )
            },
        },
    ];

    const rows = useLoadableContent(opinionList);

    return (
        <InnoDraggableModal
            title={'탐지 결과 판독 의견 목록'}
            open={true}
            width={550}
            height={450}
            // onClose={handleFlightLogOpenToggle}
        >

                <InnoTable
                    // toolbar={{
                    //     title : '',
                    //     handleChange : handleChangeSearchTextField
                    // }}
                    // order="desc"
                    // orderBy="createdAt"
                    headCells={columns}
                    // onRowClick={handleRowClick}
                    onReloadClick={reload}
                    // onContextMenu={handleContextMenuOpen}
                    rows={rows}
                    rowKey="opinionIdx"
                    // selectedValue={arrLogId.current}
                    // cellFormatter={(value, row, column) => {
                    //     return (
                    //         column.isPadding ?
                    //             (
                    //                 <TableCell key={column.id} align={column.align} padding={'none'}>
                    //                     {column.format ? column.format(value, row) : value}
                    //                 </TableCell>
                    //             ) :
                    //             (
                    //                 <TableCell key={column.id} align={column.align}>
                    //                     {column.format ? column.format(value, row) : value}
                    //                 </TableCell>
                    //             )
                    //     );
                    // }}
                    tableHeight="265px"
                />
    
            <OpinionRegisterDialog />
        </InnoDraggableModal>
    )
};

const OpinionResultModal = (): React.ReactElement => {
    const { projectId } = useRecoilValue(selectedProjectState);

    return (
        <>
            {projectId && <OpinionResult/>}
        </>
    );
};

export default OpinionResultModal;