import React from "react";
import { ProjectStats } from "service/ProjectService";
import { Bar } from "react-chartjs-2";

const StatsChart = ({ stats }: { stats: ProjectStats }): React.ReactElement => {
    let data = stats; //as ChartData;

    const newDatasets = data?.datasets?.map((d, idx) => {
        if (idx === 0) {
            return { ...d, type: "bar", borderWidth: 2, borderColor: "white", backgroundColor: "rgb(255, 99, 132)", yAxisID: "y1" };
        } else {
            return { ...d, type: "line", borderColor: "rgb(54, 162, 235)", borderWidth: 2, fill: false, yAxisID: "y" };
        }
    });

    data = { ...data, datasets: newDatasets.reverse() };
    const options = {
        scales: {
            y: {
                type: "linear",
                display: true,
                position: "left",    
            },
            y1: {
                type: "linear",
                display: true,
                position: "right",
                // grid line settings
                grid: {
                    drawOnChartArea: false // only want the grid lines for one axis to show up
                }
            }
        }
    };
    console.log("data: ", data);
    return <div>{stats?.datasets !== undefined && stats?.datasets.length > 0 && <Bar type="" data={data} options={options} />}</div>;
};
export default StatsChart;
