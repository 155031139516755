import React, { useEffect, useState, useRef, useMemo } from "react";
import { CSVLink } from "react-csv";
import {
    useSetRecoilState,
    useRecoilValueLoadable,
    useRecoilStateLoadable,
    useRecoilState
} from "recoil";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    // DialogContentText,
    DialogTitle,
    Box,
    Typography,
    Tabs,
    Tab,
    makeStyles,
    AppBar,
    Paper} from "@material-ui/core";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useRouter, useLoadableContent } from "hooks";
import { confirmState } from 'states/common';
import {
    detailProjectIdState,
    detailProjectSelector,
    detailProjectStatsSelector,
    projectListState
} from "states/project";
import {
    ProjectStats,
    exportDetectionResult,
    // updateProject,
    deleteProject,
    findProject
} from "service/ProjectService";
import StatsChart from "./StatsChart";
import { RedButton } from "components/common/colorButton";
import { findLayers } from "service/LayerService";
import { getGeoJsonAll } from "service/MapService";
import { type } from "os";

interface TabPanelProps extends React.PropsWithChildren<any> {
    value: number;
    index: number;
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
    return (
        <div role="tabPanel" hidden={value !== index} id={`simple-tabPanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabPanel-${index}`
    };
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        width: 800,
        height: 500
    },
    projectDetails: {
        opacity: 1,
        transition: "opacity 250ms ease-out, max-height 250ms ease-in",

        "& ul": {
            margin: 0,
            padding: 0
        },
        "& li": {
            listStyle: "none",
            margin: theme.spacing(0.5, 0),
            "&>dd,&>dt": {
                display: "inline",
                fontWeight: 600
            },
            "&>dd": {
                marginInline: theme.spacing(2),
                fontWeight: 400
            }
        }
    },
    listmenu: {
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.divider,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1, 2)
    },
    console: {
        overflow: "scroll",
        width: "100%",
        height: 280,
        padding: theme.spacing(2, 4),
        margin: theme.spacing(2, 0),
        // overflowY: 'scroll',
        backgroundColor: "rgba(0,0,0,.8)",
        fontFamily: "consolas monospace",
        color: "#fff",
        "&::-webkit-scrollbar": {
            width: "0.4em",
            height: "0.4em"
        },
        "&::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(255,255,255,0.00)"
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "3px",
            backgroundColor: "rgba(255,255,255,.6)"
            //outline: '1px solid rgba(255,255,255,.9)',
        },
        "&:hover::-webkit-scrollbar-thumb": {
            borderRadius: "3px",
            backgroundColor: "rgba(255,255,255,.9)",
            "-webkit-box-shadow": "inset 0 0 6px rgba(255,255,255,0.30)"

            //outline: '1px solid rgba(255,255,255,.9)',
        },
        "&::-webkit-scrollbar-thumb:window-inactive": {
            backgroundColor: "rgba(255,255,255,.4)"
        }
    }
}));

const ProjectResultDialog = (): React.ReactElement => {
    const classes = useStyles();
    const { push, query } = useRouter();
    const { enqueueSnackbar } = useSnackbar();
    const [confirm, setConfirm] = useRecoilState(confirmState);
    const setDetailProjectId = useSetRecoilState(detailProjectIdState);
    const [, reloadProjectList] = useRecoilStateLoadable(projectListState);
    const [detailProject, ] = useRecoilStateLoadable(detailProjectSelector);
    const statsProject = useRecoilValueLoadable(detailProjectStatsSelector);

    const consoleScrollRef = useRef<HTMLDivElement>(null);
    const [tabIdx, setTabIdx] = useState<number>(0);

    const handleClickExport = async () => {
        try {
            await exportDetectionResult(query?.projectId);
        } catch (e) {
            enqueueSnackbar("다운로드 중 에러 발생. 다시 시도하여주십시오,", {
                variant: "error"
            });
        }
    };

    //CSV 다운로드 //
   

    const headers = [
        { label: "순서", key: "idx" },
        { label: "X 좌표", key: "x" },
        { label: "Y 좌표", key: "y" },
        { label: "유형" ,  key : "type"},
        { label: "추론신뢰도" , key : "confidence"},
        { label: "면적(㎡)", key : "area"}
      ];
    
    type Todo = { idx: any;
        x: number;
        y: number;
        type: string;
        confidence: any;
        area: any; 
    };
    const [projectData, setprojectData] = useState<Todo[]>([]);

    const handleClickCsv = async () => {
        try {
            const findLayer = await findLayers(query?.projectId);
            csvRowData(findLayer.data);
        }   catch (e) {
            enqueueSnackbar("다운로드 중 에러 발생. 다시 시도하여주십시오,", {
                variant: "error"
            });
        }
    }

    const csvRowData = (layerList: any[]) => {

        let csvData:any[]= [];

        layerList.forEach(async (item: any) => {
            if(item.layerType === "detection"){
               const geoJsonAll =  await getGeoJsonAll(item.projectId , item.layerId);
               const featureInfo = geoJsonAll.data.geojson.features;
               
             
               featureInfo.forEach((item: any , index: any) =>{
                    const coordinates = item.geometry.coordinates[0];
                    const type = item.properties.type;
                    let minX = 0 , minY = 0 , maxX = 0 , maxY = 0;

                    for (var i = 0; i < coordinates.length; i++) {
                        if (coordinates[i][0] <= minX || i == 0) {
                             minX = coordinates[i][0];
                       };
                        if (coordinates[i][1] <= minY || i == 0) {
                             minY = coordinates[i][1];
                       }
                        if (coordinates[i][0].x >= maxX || i == 0) {
                             maxX = coordinates[i][0];
                       };
                        if (coordinates[i][1].y >= maxY || i == 0) {
                             maxY = coordinates[i][1];
                       }
                    }//min, max XY 좌표

                   
                    let infoType = "";
                    if(type == "A"){
                    infoType = "신축";
                    } else if(type == "B"){
                    infoType = "철거";
                    } else if(type == "C"){
                    infoType = "갱신";
                    } else {
                    infoType = "색변화";
                    }

                    const rowData = {
                        idx : index+1,
                        x : (maxX + minX) / 2,
                        y : (maxY + minY) / 2,
                        type : infoType,
                        confidence : item.properties.confidence + "%",
                        area : item.properties.detection_area
                    }

                    csvData.push(rowData);
                  
                });
                const csvDownBtn = document.getElementById('csvDownBtn') as HTMLInputElement;
                setprojectData(csvData);
                csvDownBtn.click();
        
            }
        });
       
    }


    const handleClickClose = () => {
        push("/");
    };

    const handleTabChange = (event: any, newValue: number) => {
        setTabIdx(newValue);
    };

    const handleClickDelete = (projectId: string) => (evt: any) => {
        setConfirm({
            open: true,
            title: `해당 프로젝트를 삭제하시겠습니까?`,
            handleCancel: (info: any) => (evt: any) => {
                setConfirm({
                    ...confirm,
                    ...info,
                    open: false,
                });
            },
            handleOk: (info: any) => async (evt: any) => {
                try {
                    await deleteProject(projectId);
                    enqueueSnackbar('정상적으로 삭제되었습니다.', {
                        variant: 'success',
                    });
                    reloadProjectList(0);
                    handleClickClose();
                } catch (e) {
                    enqueueSnackbar('삭제 중 에러발생. 다시 시도하여주십시오.', {
                        variant: 'error',
                    });
                } finally {
                    setConfirm({
                        ...confirm,
                        ...info,
                        open: false,
                    });
                }
            },
        });
    };

    useEffect(() => {
        setDetailProjectId(query?.projectId);
        return () => {
            setDetailProjectId("");
        };
    }, []);

    const result = useLoadableContent(detailProject);
    const statsResult: { stats: ProjectStats } = useLoadableContent(statsProject);

    const isProcessing = useMemo(() => {
        return result?.detail?.processStatus === 'processing'
    }, [result]);

    return (
        <Dialog open={true} onClose={handleClickClose} maxWidth="lg" aria-labelledby="project-detail-dialog">
            <DialogTitle id="form-dialog-title">{result?.detail?.title}</DialogTitle>
            <DialogContent>
                <div className={classes.root}>
                    <AppBar position="static">
                        <Tabs value={tabIdx} onChange={handleTabChange} aria-label="project detail">
                            <Tab label="정보" {...a11yProps(0)} />
                            <Tab label="통계" {...a11yProps(1)} disabled={isProcessing}/>
                            {/*<Tab label="고급" {...a11yProps(2)} disabled={isProcessing}/>*/}
                        </Tabs>
                    </AppBar>
                    <TabPanel value={tabIdx} index={0}>
                        <div className={classes.projectDetails}>
                            <Box>
                                <Typography component="div">
                                    <ul>
                                        <li>
                                            <dt>탐지항목 :</dt>
                                            <dd> {result?.detail?.detectionItemName}</dd>
                                        </li>
                                        <li>
                                            <dt>프로젝트 진행 상황 :</dt>
                                            <dd> {result?.detail?.processStatus}</dd>
                                        </li>
                                        <li>
                                            <dt>생성일 :</dt>
                                            <dd> {new Date(result?.detail?.createdAt).toLocaleString()}</dd>
                                        </li>
                                    </ul>
                                </Typography>
                            </Box>
                            <Paper className={classes.console} ref={consoleScrollRef} elevation={3}>
                                {result?.console && <div dangerouslySetInnerHTML={{ __html: result?.console }}></div>}
                            </Paper>
                        </div>
                    </TabPanel>
                    <TabPanel value={tabIdx} index={1}>
                        <StatsChart stats={statsResult.stats} />
                    </TabPanel>
                    {/*<TabPanel value={tabIdx} index={2}>*/}
                    {/*    <Typography variant="h6">프로젝트 수정/삭제</Typography>*/}
                    {/*    <List className={classes.listmenu}>*/}
                    {/*        <ListItem>*/}
                    {/*            <ListItemText secondary="프로젝트의 이름을 변경합니다.">프로젝트 이름 변경</ListItemText>*/}
                    {/*            <ListItemSecondaryAction>*/}
                    {/*                <RedButton>프로젝트 이름 변경</RedButton>*/}
                    {/*            </ListItemSecondaryAction>*/}
                    {/*        </ListItem>*/}
                    {/*        <ListItem>*/}
                    {/*            <ListItemText secondary="프로젝트를 영구히 삭제합니다.">프로젝트 삭제</ListItemText>*/}
                    {/*            <ListItemSecondaryAction>*/}
                    {/*                <RedButton>프로젝트 삭제</RedButton>*/}
                    {/*            </ListItemSecondaryAction>*/}
                    {/*        </ListItem>*/}
                    {/*    </List>*/}
                    {/*</TabPanel>*/}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleClickExport}
                    color="primary"
                    disabled={isProcessing}
                >
                    SHP 내보내기
                </Button>
                <Button
                  variant="contained"
                  onClick={handleClickCsv}
                  color="primary"
                  disabled={isProcessing}
                >
                    CSV 내보내기
                </Button>
                <CSVLink 
                    data={projectData} 
                    headers={headers} 
                    className="hidden"
                    filename="export.csv"
                    target= "_blank"
                    id="csvDownBtn">
                </CSVLink>
                <div style={{ flexGrow: 1 }} />
                <RedButton
                    // disabled={isProcessing}
                    onClick={handleClickDelete(result?.detail?.projectId)}
                >
                    프로젝트 삭제
                </RedButton>
                <Button variant="contained" onClick={handleClickClose} color="primary">
                    닫기
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProjectResultDialog;
