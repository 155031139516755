import React from 'react';
import TablePagination from "@material-ui/core/TablePagination";
import PropTypes from "prop-types";

import InnoTablePaginationActions from "./InnoTablePaginationActions";

interface InnoTablePaginationProps {
    rowsPerPageOptions?: any[];
    SelectProps?: {};
    component?: any;
    onReloadClick?: any;
    count: number;
    rowsPerPage: number;
    page: number;
    onChangePage: any;
    onChangeRowsPerPage: any;
}
const InnoTablePagination = ({ rowsPerPageOptions, SelectProps, component, count, rowsPerPage, page, onChangePage, onChangeRowsPerPage, onReloadClick }: InnoTablePaginationProps): React.ReactElement => {
    return (
        <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component={component}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={SelectProps}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            ActionsComponent={props => {
                return (<InnoTablePaginationActions onReloadClick={onReloadClick} {...props}/>)
            }}
        />
    );
};

InnoTablePagination.propTypes = {
    count: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangeRowsPerPage: PropTypes.func.isRequired,
};
InnoTablePagination.defaultProps = {
    rowsPerPageOptions: [5, 10, 25, { label: 'All', value: -1 }],
    SelectProps: {
        inputProps: { 'aria-label': 'rows per page' },
        native: true,
    },
    component: 'div',
};

export default InnoTablePagination;