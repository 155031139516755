import { makeStyles, Card, CardContent, Typography, ThemeProvider } from "@material-ui/core";
import { ConfigContext } from "config";
import React, { useContext } from "react";
import { useRecoilValue } from "recoil";
import { SelectedProject, selectedProjectState } from "states/project";

import './layerCss/legendInfo.scss';

const LegendInfo = (): React.ReactElement => {
    const classes = useStyles();
    const config = useContext<any>(ConfigContext);
    const {  detectionItem } = useRecoilValue<SelectedProject>(selectedProjectState);

    return (
       
            <ThemeProvider theme={config.theme.toolbarTheme}>
             {detectionItem == "BuildingChange" && (
                <Card className={classes.root}>
                    <div className={`${classes.legend}  ${'my-legend'}`}>
                        <div className='legend-scale'>
                            <ul className='legend-labels'>
                                <li className={classes.legendFontColor}><span style={{background:'#77E8A4'}}></span>신축</li>
                                <li className={classes.legendFontColor}><span style={{background:'#F7FC0C'}}></span>갱신</li>
                                <li className={classes.legendFontColor}><span style={{background:'#9E09FA'}}></span>색변화</li>
                                <li className={classes.legendFontColor}><span style={{background:'#F50B0B'}}></span>철거</li>
                            </ul>
                        </div>               
                    </div>
                </Card>
             )}
            </ThemeProvider>
       
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: "180px",
        right: "-100%",
        zIndex: 9999,
        maxWidth: 700,
        margin: theme.spacing(2),
        padding: 0,
        userSelect: "none",
        width: "150px"
    },
    legend : {
        backgroundColor: theme.palette.background.paper
    },

    legendFontColor : {
        color : theme.palette.text.primary

    }

}));
export default LegendInfo;
