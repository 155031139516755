import React from 'react';
import { useRecoilValue } from 'recoil';
import { useRouteMatch } from 'react-router-dom';
import {
    CssBaseline,
    Backdrop,
    CircularProgress,
    makeStyles,
    ThemeProvider,
} from '@material-ui/core';

import Map from 'Map';
import { Header, Left, Content } from './layout';
import { InnoConfirmDialog } from 'components/common';
import { ProjectRegisterDialog, ProjectResultDialog } from 'components/project';

import { backdropState, confirmState } from 'states/common';
import { useContext } from 'react';
import { ConfigContext } from 'config';

import { useCookies } from 'react-cookie';
import { useHistory  } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%'
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    }
}));

export default function MapPage() {
    const classes = useStyles();
    const config = useContext<any>(ConfigContext);
    const backdrop = useRecoilValue(backdropState);
    const confirm = useRecoilValue(confirmState);

    //cookie 확인
    //cookie 
    const [cookies] = useCookies(['login']);
    const history = useHistory();
    if(cookies.login == undefined){
        history.push("/login");
    }
    

    return (
        <Map>
            <ThemeProvider theme={config.theme.mainTheme}>
                <div className={classes.root}>
                    <CssBaseline/>
                    <>
                        <Header/>
                        <Left/>
                        <Content/>
                    </>
                    <Backdrop className={classes.backdrop} open={backdrop}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <InnoConfirmDialog
                        open={confirm.open}
                        title={confirm.title}
                        content={confirm.content}
                        handleCancel={confirm.handleCancel}
                        handleOk={confirm.handleOk}
                    />
                    {useRouteMatch('/project/register') && <ProjectRegisterDialog/>}
                    {useRouteMatch('/project/result/:id') && <ProjectResultDialog/>}
                </div>
            </ThemeProvider>
        </Map>
    );
}
