import { getIcon } from "material-file-icons";
import React from "react";
import { FolderYellow } from "components/fileExplorer/icons";
import IFileInfo from "components/fileExplorer/IFileInfo";

interface IconByRowProp {
    fileInfo: IFileInfo;
}
export default function IconByRow(props: IconByRowProp) {
    const { fileInfo } = props;

    return (
        <div
            style={{
                padding: 12,
                display: "inline-flex",
                verticalAlign: "middle",
                alignItems: "center"
            }}
        >
            {/* {fileInfo.isDirectory ? <AiOutlineFolder className="MuiSvgIcon-root" color="primary" /> : getIconByExtent(fileInfo.ext)} */}
            {fileInfo.ext !== "" ? <FileIcon filename={fileInfo.name} style={{ height: 24, width: 24 }} /> : <DirecotryIcon name={fileInfo.name} style={{ height: 24, width: 24 }} />}
        </div>
    );
}

interface FileIconProps {
    filename: string;
    style?: React.CSSProperties;
    className?: string;
}

const FileIcon = (props: FileIconProps): React.ReactElement => {
    return <div style={props.style} className={props.className} dangerouslySetInnerHTML={{ __html: getIcon(props.filename).svg }} />;
};

interface DirectoryIconProps {
    name: string;
    style?: React.CSSProperties;
    className?: string;
}

const DirecotryIcon = (props: DirectoryIconProps): React.ReactElement => {
    return (
        <div style={props.style} className={props.className}>
            <FolderYellow />
        </div>
    );
};
