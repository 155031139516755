import React, {useEffect, useState, useRef, useContext} from 'react';
import {useRecoilValue, useRecoilValueLoadable} from 'recoil';
import { getRenderPixel } from 'ol/render';
import { isLayerSwipeState, layerListSelector } from 'states/map';
import { useLoadableContent } from 'hooks';

import {
    makeStyles
} from '@material-ui/core';

import MapContext from 'Map/MapContext';
import { getMetadata } from 'service/TmsService';

const useStyles = makeStyles((theme) => ({
    bar: {
        cursor: 'col-resize',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        height: '100%',
        width: 20,
        zIndex: 1,
        position: 'absolute',
        '&>span': {
            left: 7,
            width: 5,
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.8)',
            position: 'absolute',
        },
        '&:hover': {
            '&>span': {
                backgroundColor: 'rgba(72,120,241,0.9)',
            }
        }
    }
}));

const LayerSwipeBar = (): React.ReactElement => {
    const classes = useStyles();
    const { innoMap, innoLayer } = useContext(MapContext);
    const layerList = useRecoilValueLoadable(layerListSelector);
    const [swipeLayers, setSwipeLayers] = useState<any>();
    const [left, setLeft] = useState();
    const barRef = useRef<HTMLDivElement>(null);
    const mouseHandle = useRef({
        isMouseOver: false,
        isMouseDown: false,
        isMouseEnter: false
    });

    const { leftLayerInfo, rightLayerInfo } = useLoadableContent(layerList);

    const onMouseOver = () => {
        mouseHandle.current.isMouseOver = true;
    };
    const onMouseOut = () => {
        mouseHandle.current.isMouseOver = false;
    };
    const onMouseMove = (evt: any) => {
        if (mouseHandle.current.isMouseDown && barRef.current) {
            // @ts-ignore
            if (barRef.current?.closest('div#map').querySelector('canvas') === evt.target) {
                // if (barRef.current.closest('div#swipe') === evt.target) {
                setLeft(evt.offsetX);
                innoMap.getMap().render();
            }

        }
    };
    const onMouseUp = () => {
        mouseHandle.current.isMouseDown = false;
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
    };
    const onMouseDown = () => {
        mouseHandle.current.isMouseDown = true;
        if (mouseHandle.current.isMouseEnter) {
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        }
    };
    const onMouseEnter = () => {
        mouseHandle.current.isMouseEnter = true;
    };
    const onMouseLeave = () => {
        mouseHandle.current.isMouseEnter = false;
    };

    useEffect(() => {
        if (innoMap && innoLayer && leftLayerInfo && rightLayerInfo) {
            (async () => {
                const { data: leftMeta } = await getMetadata(leftLayerInfo.projectId, leftLayerInfo.layerId);
                const { data: rightMeta } = await getMetadata(rightLayerInfo.projectId, rightLayerInfo.layerId);

                setSwipeLayers({
                    leftLayer: innoLayer.getTmsLayer(
                        leftLayerInfo.layerId,
                        `/api/v1/tms/${leftLayerInfo.projectId}/${leftLayerInfo.layerId}/tms/{z}/{x}/{y}.${leftMeta.format}`,
                        leftMeta.bounds,
                        leftMeta.minzoom,
                        leftMeta.maxzoom,
                        true
                    ),
                    rightLayer: innoLayer.getTmsLayer(
                        rightLayerInfo.layerId,
                        `/api/v1/tms/${rightLayerInfo.projectId}/${rightLayerInfo.layerId}/tms/{z}/{x}/{y}.${rightMeta.format}`,
                        rightMeta.bounds,
                        rightMeta.minzoom,
                        rightMeta.maxzoom,
                        true
                    ),
                })
            })();
        }
    }, [innoMap, innoLayer, leftLayerInfo, rightLayerInfo]);

    useEffect(() => {
        if (innoMap && swipeLayers) {
            innoMap.addLayers(swipeLayers);
            const {leftLayer, rightLayer} = swipeLayers;

            const prerenderEventHandler = (event: any) => {
                const ctx = event.context;
                const mapSize = innoMap.getMap().getSize();

                const { current } = barRef;
                // @ts-ignore
                const swipePer = current.style.left
                    // @ts-ignore
                    ? current.style.left.replace('px', '') / mapSize[0]
                    : 0.5;
                const width = mapSize[0] * swipePer;
                const tl = getRenderPixel(event, [width, 0]);
                const tr = getRenderPixel(event, [mapSize[0], 0]);
                const bl = getRenderPixel(event, [width, mapSize[1]]);
                const br = getRenderPixel(event, mapSize);

                ctx.save();
                ctx.beginPath();
                ctx.moveTo(tl[0], tl[1]);
                ctx.lineTo(bl[0], bl[1]);
                ctx.lineTo(br[0], br[1]);
                ctx.lineTo(tr[0], tr[1]);
                ctx.closePath();
                ctx.clip();
            };
            const postrenderEventHandler = (event: any) => {
                const ctx = event.context;
                ctx.restore();
            };
            rightLayer.on('prerender', prerenderEventHandler);
            rightLayer.on('postrender', postrenderEventHandler);
            return () => {
                innoMap.removeLayers(swipeLayers);
            };
        }

    }, [innoMap, swipeLayers])

    return (
        <div
            id="swipe"
            ref={barRef}
            className={classes.bar}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            style={{
                left
            }}
        >
            <span/>
        </div>
    );
};

const LayerSwipe = () => {
    const isLayerSwipe = useRecoilValue(isLayerSwipeState);

    return (
        <>
            {isLayerSwipe && (
                <LayerSwipeBar/>
            )}
        </>
    )
};

export default LayerSwipe;