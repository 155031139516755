import { atom } from 'recoil';

export const drawerWidthState = atom<number>({
    key: 'drawerWidthState',
    default: 300
});

export const drawerOpenState = atom<boolean>({
    key: 'drawerOpenState',
    default: true
});