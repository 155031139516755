import { atom, selector } from 'recoil';
import { selectedProjectState } from './project';
import * as opinionService from 'service/OpinionService';

export const forceReloadOpinionListState = atom<number>({
    key: 'forceReloadOpinionListState',
    default: 0
});

export const opinionListSelector = selector({
    key: 'opinionListSelector',
    get: async ({ get }) => {
        get(forceReloadOpinionListState);
        const { projectId } = get(selectedProjectState);
        const { data } = await opinionService.findOpinions(projectId);
        return data;
    },
    set: ({ set }, newValue = 0) => {
        set(forceReloadOpinionListState, Math.random());
    }
});

export const selectedDataIdState = atom<number|string>({
    key: 'selectedDataIdState',
    default: ''
});

export const opinionDetailSelector = selector({
    key: 'opinionDetailSelector',
    get: async ({ get }) => {
        get(forceReloadOpinionListState);
        const { projectId } = get(selectedProjectState);
        const { data } = await opinionService.findOpinionByDataId(projectId, get(selectedDataIdState));
        return data;
    }
});

export const saveOpinionParamsState = atom<any>({
    key: 'saveOpinionParamsState',
    default: {}
});


