import { register } from 'ol/proj/proj4';
import { addProjection } from 'ol/proj';
import Projection from 'ol/proj/Projection';
import proj4 from 'proj4';

class InnoProjection {
    private _code: any;
    private _projectionOpts: any[];

    constructor() {
        this._code = {
            3093: '+proj=utm +zone=52 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs',
            5181: '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=500000 +ellps=GRS80 +units=m +no_defs',
            5185: '+proj=tmerc +lat_0=38 +lon_0=125 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs',
            5186: '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs',
            5187: '+proj=tmerc +lat_0=38 +lon_0=129 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs',
            5188: '+proj=tmerc +lat_0=38 +lon_0=131 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs',
            5174: '+proj=tmerc +lat_0=38 +lon_0=127.0028902777778 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs +towgs84=-115.80,474.99,674.11,1.16,-2.31,-1.63,6.43',
            // 5179 : '+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs',
            5179: '+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
            // 5179: '+proj=tmerc +datum=WGS84 +lat_0=38.0 +lon_0=127.5 +x_0=1000000.0 +y_0=2000000.0 +ellps=GRS80 +k=0.9996 +a=6378137.0 +b=6356752.3141403',
            // 5179: '+proj=tmerc +datum=WGS84  +lat_0=38.0 +lon_0=127.5  +x_0=1000000.0 +y_0=2000000.0 +k=0.9996 +a=6378137.0 +b=6356752.3141403',
            4019: '+proj=longlat +ellps=GRS80 +no_defs'
        };

        this._projectionOpts = [
            {
                code: 'EPSG:5179',
                extent: [531371.84, 967246.47, 1576674.68, 2274021.31],
                units: 'm'
            }
        ];

        this.init();
    }

    /**
     * 객체생성 시 초기화 함수
     * @private
     */
    private init():void {
        const arrSrsCode = ["EPSG:", "http://www.opengis.net/gml/srs/epsg.xml#"];

        Object.entries(this._code).forEach(([srsCode, def]: any[string]) => {
            for (let srs of arrSrsCode) {
                proj4.defs(srs + srsCode, def);
            }
        });

        register(proj4);

        this._projectionOpts.forEach(projectionOpt => {
            addProjection(new Projection(projectionOpt));
        });
    }
}

/**
 * 즉시 실행
 */
export default (() => {
    new InnoProjection();
})();