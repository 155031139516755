import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles((theme) => ({
    title: {
        flex: '1 1 100%',
        fontWeight: 'bold'
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    search: {
        width: 300,
    }
}));

interface InnoTableToolbarProps {
    title: string;
    onChange: any
}

const InnoTableToolbar = (props: InnoTableToolbarProps): React.ReactElement => {
    const classes = useStyles();
    const { onChange, title } = props;

    return (
        <Toolbar>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                {title}
            </Typography>

            <TextField
                type="search"
                placeholder="Search"
                className={classes.search}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                    )
                }}
                onChange={onChange}
            />
        </Toolbar>
    );
};

InnoTableToolbar.defaultProps = {
    title: "",
    onChange: () => {
    },
};

export default InnoTableToolbar;