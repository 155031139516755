import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";

interface InnoTableHeadProps {
    headCells: any[];
    onRequestSort: any;
    orderBy?: string;
    order?: 'asc' | 'desc';
}

const useStyles = makeStyles((theme) => ({
    tableCell: {
        //backgroundColor: '#ffffff',
        fontWeight: 'bold'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const InnoTableHead = (props: InnoTableHeadProps): React.ReactElement => {
    const classes = useStyles();
    const { headCells, orderBy, order } = props;
    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableCell}
                        style={{ minWidth: column.minWidth }}
                        sortDirection={orderBy === column.id ? order : false}
                    >
                        {column.isOrder || false ? (
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'asc'}
                                onClick={createSortHandler(column.id)}
                            >
                                {column.label}
                                {
                                    orderBy === column.id ?
                                        (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) :
                                        null
                                }
                            </TableSortLabel>
                        ) : (
                            column.label
                        )}

                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

InnoTableHead.propTypes = {
    headCells: PropTypes.array.isRequired,
};
InnoTableHead.defaultProps = {
    headCells: [],
    onRequestSort: () => {
    },
};

export default InnoTableHead;