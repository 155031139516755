import { DefaultValue } from "recoil";

const localStorageEffect = (key:string) => ({ setSelf, onSet }:{ setSelf:any, onSet:any }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
    }

    onSet((newValue:any) => {
        if (newValue instanceof DefaultValue) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, JSON.stringify(newValue));
        }
    });
};

export default localStorageEffect