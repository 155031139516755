import React from "react";
import { useRecoilValue } from "recoil";
import clsx from "clsx";
import {
    LayerSwipe,
    MapControl,
    // MapBackground,
    TitleInfo,
    //Inference,
    LegendInfo
} from "components/map";
import { makeStyles } from "@material-ui/core";
// import MapContext from 'Map/MapContext';
import { drawerOpenState, drawerWidthState } from "states/layout";

const useStyles = (drawerWidth: number) =>
    makeStyles((theme) => ({
        content: {
            flex: "1 0 auto",
            display: "flex",
            flexDirection: "column",
            // transition: theme.transitions.create('margin', {
            //     easing: theme.transitions.easing.sharp,
            //     duration: theme.transitions.duration.leavingScreen,
            // }),
            marginLeft: -drawerWidth
        },
        contentShift: {
            // transition: theme.transitions.create('margin', {
            //     easing: theme.transitions.easing.easeOut,
            //     duration: theme.transitions.duration.enteringScreen,
            // }),
            marginLeft: 0
        },
        contentHeader: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: "flex-end"
        }
    }));

const Content = (): React.ReactElement => {
    const drawerWidth = useRecoilValue(drawerWidthState);
    const classes = useStyles(drawerWidth)();
    // const { innoBackgroundMap } = useContext(MapContext);
    const open = useRecoilValue(drawerOpenState);

    return (
        <main
            className={clsx(classes.content, {
                [classes.contentShift]: open
            })}
        >
            <div className={classes.contentHeader} />

            <MapControl>
                <TitleInfo />
                {/* <Inference /> */}
                <LegendInfo />
                {/*<MapBackground backgroundMap={innoBackgroundMap} />*/}
                <LayerSwipe />
            </MapControl>
        </main>
    );
};

export default Content;
