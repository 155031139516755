import React, { useLayoutEffect } from "react";
import { useRecoilStateLoadable, useRecoilState, useSetRecoilState, useRecoilValueLoadable } from "recoil";
import { Avatar, Card, CardHeader, Chip, Tooltip, CardContent, makeStyles, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, CircularProgress } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ReplayIcon from "@material-ui/icons/Replay";
import ViewListIcon from "@material-ui/icons/ViewList";
import DomainDisabledTwoToneIcon from "@material-ui/icons/DomainDisabledTwoTone";
import ApartmentIcon from "@material-ui/icons/Apartment";
import EcoIcon from "@material-ui/icons/Eco";
import SettingsBrightnessIcon from "@material-ui/icons/SettingsBrightness";
import { useRouter, useLoadableContent } from "hooks";
import { codeInfoListSelector, projectListState, selectedProjectState } from "states/project";
import { isLayerSwipeState, isMapSplitState } from "states/map";

type ProcessingStatus = "ready" | "processing" | "finished";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: "0 0 1",
        //width: "100%",
        //maxWidth: 360,
        margin: theme.spacing(0, 1, 1)
        //backgroundColor: theme.palette.background.paper
    },
    avatar: {
        background: "transparent",
        color: theme.palette.primary.main
    },
    cardContent: {
        padding: 0,
        overflowY: "auto",
        height: "calc(100% - 54px)",
        "&::-webkit-scrollbar": {
            width: "0.4em",
            height: "0.4em"
        },
        "&::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(255,255,255,0.00)"
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "3px",
            backgroundColor: "rgba(255,255,255, 0)",
            //outline: '1px solid rgba(255,255,255,.9)',
            transitions: "backgroundColor .4s ease"
        },
        "&:hover::-webkit-scrollbar-thumb": {
            borderRadius: "3px",
            backgroundColor: "rgba(255,255,255,.9)",
            "-webkit-box-shadow": "inset 0 0 6px rgba(255,255,255,0.30)",
            transitions: "backgroundColor .4s ease"

            //outline: '1px solid rgba(255,255,255,.9)',
        },
        "&::-webkit-scrollbar-thumb:window-inactive": {
            backgroundColor: "rgba(255,255,255,0)"
        }
    },
    listroot: {
        width: "100%",
        maxWidth: 360
        //backgroundColor: theme.palette.background.paper
    },
    progress: {
        verticalAlign: "middle",
        float: "right"
    }
}));

const ProjectList = (): React.ReactElement => {
    const classes = useStyles();
    const { history } = useRouter();

    const [projectList, reload] = useRecoilStateLoadable(projectListState);
    const [selectedProject, setSelectedProject] = useRecoilState(selectedProjectState);
    const setIsLayerSwipe = useSetRecoilState(isLayerSwipeState);
    const setIsMapSplit = useSetRecoilState(isMapSplitState);
    const codeInfoList = useRecoilValueLoadable(codeInfoListSelector);

    const handleClickDetail = (id: string) => () => {
        history.push(`/project/result/${id}`);
    };

    const handleClickReload = () => {
        reload(0);
    };

    const handleClickRow = (row: any) => () => {
        const { projectId, year, detectionItem, title } = row;
        setSelectedProject({ projectId, year, detectionItem, title });
    };

    useLayoutEffect(() => {
        if (selectedProject?.detectionItem === "BuildingChange") {
            setIsMapSplit(true);
        }
        return () => {
            setIsLayerSwipe(false);
            setIsMapSplit(false);
        };
    }, [selectedProject, setIsLayerSwipe, setIsMapSplit]);
    const getSggName = (sggCde: string) => {
        const result = codeInfoList.contents.find((d: any) => d.codeId === sggCde);

        return result ? result?.codeName : "알 수 없음";
    };

    const StatusChip = ({ processingStatus }: { processingStatus: ProcessingStatus }) => {
        switch (processingStatus) {
            case "processing":
                return <Chip size="small" label="처리 중" color="primary" />;
            case "finished":
                return <Chip size="small" label="처리완료" />;
            case "ready":
                return <Chip size="small" label="대기 중" color="secondary"/>;
        }
    };

    const detectionItemtoIcon = (detectionItem: string) => {
        switch (detectionItem) {
            case "BuildingChange":
                return (
                    <Tooltip title="건물변화탐지">
                        <DomainDisabledTwoToneIcon color="secondary" />
                    </Tooltip>
                );
            case "Building":
                return (
                    <Tooltip title="건물탐지">
                        <ApartmentIcon color="secondary" />
                    </Tooltip>
                );
            case "Vegetation":
                return (
                    <Tooltip title="녹지탐지">
                        <EcoIcon color="secondary" />
                    </Tooltip>
                );
            case "SolarP":
                return (
                    <Tooltip title="태양광패널탐지">
                        <SettingsBrightnessIcon color="secondary" />
                    </Tooltip>
                );
            default:
                return "";
        }
    };

    return (
        <>
            <Card className={classes.root}>
                <CardHeader
                    title="프로젝트 목록"
                    style={{ padding: 8 }}
                    avatar={
                        <Avatar className={classes.avatar}>
                            <ViewListIcon />
                        </Avatar>
                    }
                    action={
                        <IconButton onClick={handleClickReload} size="small" style={{ margin: 8 }}>
                            <ReplayIcon />
                        </IconButton>
                    }
                />
                <CardContent className={classes.cardContent}>
                    <List className={classes.listroot}>
                        {useLoadableContent(projectList).map((result: any, idx: number) => {
                            return (
                                <Tooltip title={getSggName(result?.sggCde)}>
                                    <ListItem
                                        key={idx}
                                        role={undefined}
                                        dense
                                        button
                                        selected={selectedProject?.projectId === result.projectId}
                                        disabled={result?.processStatus === 'processing'}
                                        onClick={handleClickRow(result)}
                                    >
                                        <ListItemIcon>{detectionItemtoIcon(result?.detectionItem)}</ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <>
                                                    <span>{result?.title}</span>
                                                    <div style={{ flexGrow: 1 }}></div>
                                                    <StatusChip processingStatus={result?.processStatus} />
                                                    {/* {result?.processStatus === "processing" && <CircularProgress size={20} className={classes.progress} />} */}
                                                </>
                                            }
                                            secondary={getSggName(result?.sggCde)}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                size="small"
                                                onClick={handleClickDetail(result.projectId)}
                                            >
                                                <SearchIcon fontSize="small" />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </Tooltip>
                            );
                        })}
                    </List>
                </CardContent>
            </Card>
        </>
    );
};

export default ProjectList;
