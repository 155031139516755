import React, { useState, useEffect } from 'react';
import MapContext from './MapContext';
import { InnoMap, InnoBackgroundMap, InnoLayer } from 'libs/gis';

const Map = ({ children }: any): React.ReactElement => {
    const [innoObj, setInnoObj] = useState({});

    useEffect(() => {
        const innoBackgroundMap = new InnoBackgroundMap('');
        // const innoMap = new InnoMap('map', {}, ['measure']);
        const innoMap = new InnoMap('map', {}, innoBackgroundMap.getBackgroundInfo());

        const innoLayer = new InnoLayer();
        innoMap.addLayers(innoBackgroundMap.getLayers());
        // innoMap.addLayers(innoLayer.getLayers());

        setInnoObj({ innoMap, innoBackgroundMap, innoLayer });
        return () => innoMap.getMap().setTarget(undefined);
    }, []);

    return <MapContext.Provider value={innoObj}>{children}</MapContext.Provider>;
};

export default Map;
