import React, {useContext, useEffect, useState} from 'react';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import {
    makeStyles,
    Paper,
    Checkbox,
    Typography
} from '@material-ui/core';
import {
    TreeView,
    TreeItem
} from '@material-ui/lab';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MapContext from 'Map/MapContext';
import { getGeoJson, getGeoJsonAll,typeCount, typeCountAll } from 'service/MapService';
import { getMetadata } from 'service/TmsService';
import { useSnackbar } from 'notistack';
import { selectedDataIdState, saveOpinionParamsState } from 'states/opinion';
import LayerInfoBox from './LayerInfoBox';
import { isInferenceSel,  isLayerGroupCnt,  isLayerGroupV,  layerListSelector } from 'states/map';
import { Style } from 'ol/style';



const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        marginRight: theme.spacing(2),
        right: 0,
        top: 15,
        zIndex: 1,
        overflow:'hidden',
        // padding: theme.spacing(1, 1),
        minWidth: 150,
        userSelect:'none',
        '&>.MuiTypography-root': {
            padding: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontSize:'1.2rem'
        },
        '&>.MuiTreeView-root': {
            padding: theme.spacing(1)
        }
    },
    treeRoot: {
        flexGrow: 1,
    },
}));

interface SubLayersProps {
    innoMap?: import('libs/gis').InnoMap
    year?: number
    layers?: any[]
}

const SubLayers = ({ innoMap, year, layers }: SubLayersProps): React.ReactElement => {
    const classes = useStyles();
    const setSelectedDataId = useSetRecoilState(selectedDataIdState);
    const setSaveOpinionParams = useSetRecoilState(saveOpinionParamsState);
    const { enqueueSnackbar } = useSnackbar();
    const inferenceVal = useRecoilValue(isInferenceSel);
    //const inLayerSelVal = useRecoilValue(isLayerGroupSel);
    const inLayerSelVal = useRecoilValue(isLayerGroupV);
    //const getGroupCnt = useRecoilValue(isLayerGroupCnt);
    const [getGroupCnt, setGetGroupCnt] = useRecoilState(isLayerGroupCnt);

    const { innoLayer } = useContext(MapContext);
    const { innoBackgroundMap} = useContext(MapContext);
    const [subLayersState, setSubLayersState] = useState<import('ol/layer').Layer[]>([]);

    const handleCheckLayer = (layerId: number) => (evt: any,checked: boolean ) => {
        const layer = subLayersState.find(layer => layer.get('id') === layerId);
        if (layer) {
            layer.setVisible(checked);
        }
    };

    // console.log(innoBackgroundMap);
    // const handleCheckLayer_back = (layerId: number) => (evt: any,checked: boolean ) => {
    //     alert("33")
    // };

    useEffect(() => {
        if (layers && innoMap && innoLayer) {
            (async () => {
                const subLayers: import('ol/layer').Layer[] = [];
                let idx = 0;
                let chk = true;
                layers.forEach(async item =>{
                   if(item.layerType == "detection" && chk){
                        const { data } = await typeCountAll(item.projectId, item.layerId);
                        layerCheckCnt(data[0]);
                        chk = false;
                   }
                });

                for (const layer of layers) {
                    const { projectId, layerId } = layer;
                    if (layer.layerType !== 'tms') {
                            const wfsLayer = innoLayer.getWfsLayer(layerId, layer, async (extent: import('ol/extent').Extent) => {
                            const { data } = await getGeoJson(projectId, layerId, extent);                            
                            return data.geojson;                            
                        });

                        layer.layerType === 'cad'
                            ? wfsLayer.setStyle(innoLayer.getCadStyle)
                            : wfsLayer.setStyle(innoLayer.getAnalysisStyle)
                            
                        subLayers.push(wfsLayer);
                    } else {

                         const { data: { format, bounds, minzoom, maxzoom, center } } = await getMetadata(projectId, layerId);
                         if (idx === 0) innoMap.setCenter(center);

                             subLayers.push(
                                innoLayer.getTmsLayer(
                                layerId,
                                `/api/v1/tms/${projectId}/${layerId}/tms/{z}/{x}/{y}.${format}`,
                                bounds,
                                minzoom,
                                maxzoom
                            )
                        );
                        idx++;
                    }
                }

                subLayers.push(innoLayer.getVectorLayer());
                setSubLayersState(subLayers);
            })();
        }
    }, [layers, innoMap, innoLayer]);

    useEffect(() => {
        if (innoMap && innoLayer && subLayersState) {
            const clickHandler = (evt: any) => {
                if (evt.pixel) {
                    innoMap.getMap().forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
                        // cb(feature, layer);
                        if (layer.getVisible() && layer.get('layerType') === 'detection') {
                            //innoMap.selectByFeature(feature);
                            setSaveOpinionParams({
                                geom: innoMap.featureToWKT(feature),
                                dataId: feature.get('data_id'),
                                projectId: layer.get('projectId'),
                            });
                            setSelectedDataId(feature.get('data_id'));
                            LayerInfoBox(feature,innoMap);
                        }
                    }, { hitTolerance : 1 });
                }
            };

            const moveEndHandler = (evt: any) => {
                subLayersState.forEach((subLayerState) => {
                    if (subLayerState.get('kind') === 'wfs'
                        && subLayerState.getVisible()
                        && evt.target.getView().getResolution() > innoLayer.wfsMaxResolution
                    ) {
                        enqueueSnackbar(`현재 레벨에서는 ${subLayerState.get('layerName')} 레이어를 불러올 수 없습니다.`, {
                            variant: 'warning'
                        });
                    }
                });            
            };

            innoMap.getMap().on('click', clickHandler);
            innoMap.getMap().on('moveend', moveEndHandler);
            innoMap.addLayers(subLayersState);

            return () => {
                innoMap.getMap().un('click', clickHandler);
                innoMap.getMap().un('moveend', moveEndHandler);
                innoMap.removeLayers(subLayersState);
            }
            
        }
    }, [subLayersState, innoMap, innoLayer])

    useEffect(() => {
        if (layers && innoMap && innoLayer) {
            layers.forEach(async (layers) => {
              
              if(layers.layerType == "detection"){
                //@ts-ignore
                const inferenceData = document.getElementsByName('isInference')[0].value;
                const  data  =  await typeCount(layers.projectId, layers.layerId, inferenceData);
                layerCheckCnt(data.data[0]);

                //@ts-ignore
                const featureList = innoMap.getMap().getLayers().getArray().find(layer => layer.get('id') === layers.layerId).getSource().getFeatures();
                //layerCheckCnt_sub(featureList);
                featureList.forEach((item: { values_: {type: any; confidence: any } ,setStyle: (arg0: Style) => void }) => {
                    const val =  item.values_.confidence;
                    const type = item.values_.type;
                    
                    //inLayerSelVal
                    if(inLayerSelVal.A && inLayerSelVal.B && inLayerSelVal.C && inLayerSelVal.D){
                        if((val >= Number(inferenceVal)|| Number(inferenceVal) == 0 )&& Number(inferenceVal) != 49){
                            item.setStyle(innoLayer.getAnalysisStyle(item))
                        }  else  if(Number(inferenceVal) == 49 && val <= Number(inferenceVal)  ){
                            item.setStyle(innoLayer.getAnalysisStyle(item))
                        }  else {
                            item.setStyle(new Style({}))
                        }
                    } else if((inLayerSelVal.A && type == 'A') || (inLayerSelVal.B && type == 'B') || (inLayerSelVal.C && type == 'C') || (inLayerSelVal.D && type == 'D')){
                        if((val >= Number(inferenceVal) || Number(inferenceVal) == 0 )&& Number(inferenceVal) != 49){
                            item.setStyle(innoLayer.getAnalysisStyle(item))
                        }  else  if(Number(inferenceVal) == 49 && val <= Number(inferenceVal)  ){
                            item.setStyle(innoLayer.getAnalysisStyle(item))
                        }  else {
                            item.setStyle(new Style({}))
                        }
                    } else {
                        item.setStyle(new Style({}))
                    }
                })
              }
            });
           
        }
    }, [inferenceVal,inLayerSelVal])

    const layerCheckCnt = (feature : any) =>{
        let typeA = feature.typea;
        let typeB = feature.typeb;
        let typeC = feature.typec;
        let typeD = feature.typed;

        // //추론신뢰도 chk 
        // feature.forEach((item: { properties: {confidence: any; type: String; }; }) =>{
        //     //confidence            
        //     const val = item.properties.confidence;
        //     const type = item.properties.type;

        //     if((val >= Number(inferenceVal) || Number(inferenceVal) == 0 )&& Number(inferenceVal) != 49){
        //         if(type == "A"){
        //             typeA++;
        //         } else if(type == "B"){
        //             typeB++;
        //         } else if(type == "C"){
        //             typeC++;
        //         } else if(type == "D"){
        //             typeD++;
        //         } else {

        //         }
        //     } else if(Number(inferenceVal) == 49 && val <= Number(inferenceVal)){
        //         if(type == "A"){
        //             typeA++;
        //         } else if(type == "B"){
        //             typeB++;
        //         } else if(type == "C"){
        //             typeC++;
        //         } else if(type == "D"){
        //             typeD++;
        //         } else {

        //         }
        //     }

            setGetGroupCnt({
                A: "신축(" + typeA + ")",
                B : "철거(" + typeB + ")",
                C : "갱신(" + typeC + ")",
                D : "색변화(" + typeD + ")"
           });
            // getGroupCnt.A = 
            // getGroupCnt.B = 
            // getGroupCnt.C = 
            // getGroupCnt.D = 

           
        //})
        
       


    }

 
    
    return (
        <Paper className={classes.paper}>
            <Typography variant="h6">
                {year?`${year}년 레이어`:'레이어 목록'}
            </Typography>
            <TreeView
                className={classes.treeRoot}
                disableSelection={true}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                // defaultEndIcon={<Checkbox color="primary" />}
                // defaultExpanded={['base']}
            >
                   {/* <TreeItem
                        key={0}
                        nodeId={`test`}
                        label={`test`}
                        endIcon={<Checkbox color="primary"  onChange={handleCheckLayer_back(2)} defaultChecked />}
                    /> */}
                {layers?.map((layer: any, idx: number) => (
                    <TreeItem
                        key={idx}
                        nodeId={layer?.layerId.toString()}
                        label={layer?.layerName}
                        endIcon={<Checkbox color="primary" onChange={handleCheckLayer(layer.layerId)} defaultChecked/>}
                    />
                ))}
            </TreeView>
        </Paper>
    );
};

interface SubLayersControlProps extends SubLayersProps {
    open: boolean;
}

const SubLayersControl = ({ open, ...other }: SubLayersControlProps): React.ReactElement => {
    return (
        <>
            {open && (
                <SubLayers {...other} />
            )}
        </>
    );
};
export default SubLayersControl;