import React, { useContext, useEffect, useState } from 'react';
import {useRecoilValueLoadable, useRecoilValue, useRecoilState} from 'recoil';
import {
    SubLayersControl,
    // MapBackground
} from './index';
import { InnoMap, InnoBackgroundMap } from 'libs/gis';
import MapContext from 'Map/MapContext';
import {drawerOpenState} from "states/layout";
import { layerListSelector } from 'states/map';
import { useLoadableContent } from 'hooks';

const SplitMapControl = ({ children, ...other }: any): React.ReactElement => {
    const { innoMap } = useContext(MapContext);

    const drawerOpen = useRecoilValue<boolean>(drawerOpenState);
    const layerList = useRecoilValueLoadable(layerListSelector);
    const { rightLayers } = useLoadableContent(layerList);

    const [innoMapState, setInnoMapState] = useState<InnoMap>();
    const [backgroundMap, setBackgroundMap] = useState<InnoBackgroundMap>();

    useEffect(() => {
        if (innoMap) {
            const secondInnoBackgroundMap = new InnoBackgroundMap();
            const secondInnoMap = new InnoMap('secondMap', {}, secondInnoBackgroundMap.getBackgroundInfo());

            secondInnoMap.addLayers(secondInnoBackgroundMap.getLayers());
            setBackgroundMap(secondInnoBackgroundMap);

            innoMap.onSyncView(secondInnoMap.getMap());
            secondInnoMap.onSyncView(innoMap.getMap());
            secondInnoMap.updateSize();

            setInnoMapState(secondInnoMap);

            return () => {
                innoMap.updateSize();
                innoMap.offSyncView();
            }
        }
    }, [innoMap]);

    useEffect(() => {
        if (innoMapState) {
            innoMapState.updateSize();
        }
    }, [drawerOpen, innoMapState])

    return (
        <div id="secondMap" {...other} style={{
            width: '50%',
        }}>
            {/*<MapBackground backgroundMap={backgroundMap} />*/}
            <SubLayersControl
                innoMap={innoMapState}
                open={true}
                year={rightLayers?.find((e: any) => e.year).year}
                layers={rightLayers}
            />
        </div>
    );
}

export default SplitMapControl;