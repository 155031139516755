import React, {useState, useEffect, useMemo, useContext} from 'react';
import {
    useRecoilValue,
    useRecoilState,
    useRecoilStateLoadable,
    useRecoilValueLoadable
} from 'recoil';
import {
    makeStyles,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
    opinionDetailSelector,
    opinionListSelector,
    selectedDataIdState,
    saveOpinionParamsState
} from 'states/opinion';
import { useLoadableContent } from 'hooks';
import { updateOpinion, insertOpinion } from 'service/OpinionService';
import MapContext from "Map/MapContext";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        overflow: 'hidden'
    }
}));

const OpinionRegister = (): React.ReactElement => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { innoMap } = useContext(MapContext);
    const [, reload] = useRecoilStateLoadable(opinionListSelector);
    const opinionDetail = useRecoilValueLoadable(opinionDetailSelector);
    const [, setSelectedDataId] = useRecoilState(selectedDataIdState);
    const [saveOpinionParams, setSaveOpinionParams] = useRecoilState(saveOpinionParamsState);

    const [isEditDialog, setIsEditDialog] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>({});

    const dialogKind = useMemo(() => {
        return isEditDialog ? '수정' : '저장';
    }, [isEditDialog]);

    const handleClose = () => {
        setSelectedDataId('');
        setSaveOpinionParams({});
        innoMap.getSourceByKind('temp').clear();
    };

    const handleChangeFormData = (evt: any) => {
        setFormData({
            ...formData,
            [evt.target.name]: evt.target.value
        });
    };

    const handleClickEdit = async (evt: any) => {
        try {
            if (isEditDialog) {
                await updateOpinion(formData.projectId, formData.opinionIdx, formData);
            } else {
                await insertOpinion(formData.projectId, formData.dataId, formData);
            }

            enqueueSnackbar(`정상적으로 ${dialogKind}되었습니다.`, {
                variant: 'success',
            });
            reload(0);
        } catch (e) {
            enqueueSnackbar(`${dialogKind} 중 에러발생. 다시 시도하여주십시오.`, {
                variant: 'error',
            });
        } finally {
            handleClose();
        }
    };

    const result = useLoadableContent(opinionDetail);

    useEffect(() => {
        if (result && !(result instanceof Array)) {
            const { projectId, dataId, opinionIdx, isDetection, comment } = result;
            setFormData({
                projectId, dataId, opinionIdx, isDetection, comment
            });
            setIsEditDialog(true);
        } else if (!result) {
            setFormData({
                ...saveOpinionParams,
                isDetection: false
            });
            setIsEditDialog(false);
        }
    }, [result, saveOpinionParams])

    return (
        <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-edit">
            <DialogTitle id="form-dialog-title">의견목록 {dialogKind}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container spacing={2}>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={3}>
                            탐지결과
                        </Grid>
                        <Grid item xs={9}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <Select
                                    id="isDetection"
                                    name="isDetection"
                                    value={Number(formData?.isDetection ?? 0)}
                                    onChange={handleChangeFormData}
                                >
                                    <MenuItem value={0}>
                                        오탐지
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        탐지 성공
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={3}>
                            의견
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                id="comment"
                                name="comment"
                                multiline
                                fullWidth
                                size="small"
                                value={formData?.comment}
                                variant="outlined"
                                onChange={handleChangeFormData}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                >
                    닫기
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickEdit}
                >
                    {dialogKind}
                </Button>
            </DialogActions>
        </Dialog>
    )
};

const OpinionRegisterDialog = ({ ...other }: any): React.ReactElement => {
    const selectedDataId = useRecoilValue(selectedDataIdState);

    return (
        <>
            {!!selectedDataId && <OpinionRegister {...other} />}
        </>
    );
};

export default OpinionRegisterDialog;