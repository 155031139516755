import React, { useState } from "react";
import { useSetRecoilState, useRecoilValueLoadable } from "recoil";
import {
    Avatar,
    Button,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    makeStyles,
    Collapse,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Typography,
    InputAdornment,
    IconButton
} from "@material-ui/core";
import { useLoadableContent } from "hooks";
import { projectSearchState, codeInfoListSelector } from "states/project";
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: "transparent",
        color: theme.palette.primary.main
    },
    paper: {
        flex: "none",
        margin: theme.spacing(1, 1),
        padding: theme.spacing(0, 0, 2),
        "& > *": {
            padding: theme.spacing(1, 2)
        }
    },
    actions: { padding: theme.spacing(0) },
    search: {
        margin: 0,
        padding: theme.spacing(0),
        "& > *": {
            margin: theme.spacing(1, 0)
        }
    },
    searchTuneBtn: {
        root: {
            borderRadius: theme.spacing(2)
        }
    }
}));

const ProjectSearch = (): React.ReactElement => {
    const classes = useStyles();
    const [searchParams, setSearchParams] = useState<any>({});
    const [isQueryExtent, setQueryExtent] = useState<boolean>(false);
    const setProjectSearch = useSetRecoilState(projectSearchState);
    const codeInfoList = useRecoilValueLoadable(codeInfoListSelector);

    const handleChangeSearchParams = (evt: any) => {
        setSearchParams({
            ...searchParams,
            [evt.target.name]: evt.target.value || undefined
        });
    };

    const handleClickSearch = () => {
        setProjectSearch(searchParams);
    };

    return (
        <Card className={classes.paper}>
            <CardHeader
                title="프로젝트 검색"
                avatar={
                    <Avatar className={classes.avatar}>
                        <SearchIcon />
                    </Avatar>
                }
                style={{ padding: 8 }}
            />
            <CardContent style={{ margin: 0, padding: "0 16px" }}>
                <FormControl variant="outlined" fullWidth size="small">
                    <TextField
                        id="title"
                        name="title"
                        label="프로젝트명"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={searchParams?.title ?? ""}
                        onChange={handleChangeSearchParams}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton className={classes.searchTuneBtn} size="small" color="primary" onClick={(e) => setQueryExtent(!isQueryExtent)}>
                                        <TuneIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </FormControl>
            </CardContent>
            <Collapse in={isQueryExtent} timeout="auto" unmountOnExit>
                <CardContent className={classes.search}>
                    <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="sggCde-select-filled-label">시군구</InputLabel>
                        <Select label="시군구" id="sggCde" name="sggCde" value={searchParams?.sggCde ?? ""} onChange={handleChangeSearchParams}>
                            <MenuItem value={""}>
                                <em>--전체--</em>
                            </MenuItem>
                            {useLoadableContent(codeInfoList).map((codeInfo: any, idx: number) => (
                                <MenuItem key={idx} value={codeInfo.codeId}>
                                    {codeInfo.codeName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        id="year"
                        name="year"
                        type="number"
                        label="연도"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={searchParams?.year ?? ""}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">년</InputAdornment>
                        }}
                        onChange={handleChangeSearchParams}
                    />
                </CardContent>
                <CardActions className={classes.actions}>
                    <Button variant="contained" color="primary" fullWidth={true} onClick={handleClickSearch}>
                        검색
                    </Button>
                </CardActions>
            </Collapse>
        </Card>
    );
};

export default ProjectSearch;
