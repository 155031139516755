import { withStyles } from "@material-ui/core";
import { red, green, blue, orange } from "@material-ui/core/colors";
import Radio, { RadioProps } from "@material-ui/core/Radio";

const RedRadio = withStyles({
    root: {
        color: red[400],
        "&$checked": {
            color: red[600]
        }
    },
    checked: {}
})((props: RadioProps) => <Radio color="default" {...props} />);

const GreenRadio = withStyles({
    root: {
        color: green[400],
        "&$checked": {
            color: green[600]
        }
    },
    checked: {}
})((props: RadioProps) => <Radio color="default" {...props} />);

const BlueRadio = withStyles({
    root: {
        color: blue[400],
        "&$checked": {
            color: blue[600]
        }
    },
    checked: {}
})((props: RadioProps) => <Radio color="default" {...props} />);

const OrangeRadio = withStyles({
    root: {
        color: orange[400],
        "&$checked": {
            color: orange[600]
        }
    },
    checked: {}
})((props: RadioProps) => <Radio color="default" {...props} />);

export { RedRadio, GreenRadio, BlueRadio, OrangeRadio };
