import React, {useContext, useEffect, useState} from 'react';
import { makeStyles, Card,Typography, ThemeProvider, FormControl, Select, MenuItem, Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import { ConfigContext } from "config";
import { Bar } from "react-chartjs-2";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { isInferenceSel,  isLayerGroupCnt,  isLayerGroupV } from "states/map";
import { SelectedProject, selectedProjectState } from "states/project";
import MapContext from 'Map/MapContext';
import { typeCount } from 'service/MapService';

function TitleInfo(): React.ReactElement {
    const classes = useStyles();
    const config = useContext<any>(ConfigContext);
    const { title, detectionItem, year } = useRecoilValue<SelectedProject>(selectedProjectState);

    const detectionItemtoKor = (detectionItem: string) => {
        switch (detectionItem) {
            case "BuildingChange":
                return "건물변화탐지";
            case "Building":
                return "건물탐지";
            case "Vegetation":
                return "녹지탐지";
            case "SolarP":
                return "태양광패널탐지";
            default:
                return "";
        }
    };

    //신뢰추론도
    const [formData, setFormData] = useState<any>({});
    const [getInfer, setInference] = useRecoilState(isInferenceSel);
    const handleChangeFormData = (evt: any) => {
        setFormData({
            ...formData,
            [evt.target.name]: evt.target.value
        });
        setInference(Number(evt.target.value));
    };

    //클래스
    const [formDataLayer, setFormDataLayer] = React.useState({
        A: true,
        B: true,
        C: true,
        D: true
    });
    const { A, B, C, D } = formDataLayer;
    const { innoLayer } = useContext(MapContext);
    const isLayerGroup = useSetRecoilState(isLayerGroupV);
    const [getGroupCnt, setGetGroupCnt] = useRecoilState(isLayerGroupCnt);
    

    const handleChangeFormDataLayer = (evt: any) => {

        setFormDataLayer({
            ...formDataLayer,
            [evt.target.name]: evt.target.checked
        });
        
        isLayerGroup({
            ...formDataLayer,
            [evt.target.name]: evt.target.checked
        });
        
    };

    useEffect(() => {
        //title 이 변경이 될때마다 실행
        setFormData({
            ...formData,
            ["isInference"]: 0
        });

         setFormDataLayer({
             ...formDataLayer,
             ["A"] : true,
             ["B"] : true,
             ["C"] : true,
             ["D"] : true
        });
    }, [title]);

    return (
        <ThemeProvider theme={config.theme.toolbarTheme}>
            {title && title.length > 1 && (
                <Card className={`${classes.root}`}>
                    <Typography className={`${classes.detectionTitle}`} variant="h5">{title}</Typography>
                    <Typography className={classes.detectionItem} variant="h6">
                        {year} {year && "|"} {detectionItemtoKor(detectionItem as string)}
                    </Typography>
                    <Typography className={classes.detectionContent} style={{ display: "flex" }}>
                        <Typography className={classes.detectionLabel}>추론신뢰도(%)</Typography>
                        <FormControl variant="outlined" fullWidth size="small">
                            <Select
                                id="isInference"
                                name="isInference"
                                className={classes.detectionSelect}
                                style={{ zIndex: 10000 }}
                                value={Number(formData?.isInference ?? 0)}
                                onChange={handleChangeFormData} IconComponent={Bar}>
                                <MenuItem value={0}>
                                    전체
                                </MenuItem>
                                <MenuItem value={100}>
                                    100%
                                </MenuItem>
                                <MenuItem value={90}>
                                    90% ▲
                                </MenuItem>
                                <MenuItem value={80}>
                                    80% ▲
                                </MenuItem>
                                <MenuItem value={70}>
                                    70% ▲
                                </MenuItem>
                                <MenuItem value={60}>
                                    60% ▲
                                </MenuItem>
                                <MenuItem value={50}>
                                    50% ▲
                                </MenuItem>
                                <MenuItem value={49}>
                                    50% ▼
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Typography>
                    {detectionItem == "BuildingChange" && (
                        //<Typography className={classes.detectionContent} style={{display:"flex"}}>
                        <Typography className={classes.detectionContent}>
                            <Typography className={classes.detectionLabel}>클래스 </Typography>
                            <FormControl variant="outlined"  size="small" className={classes.detectionCheck}>
                                <FormGroup aria-label="position" row >
                                    <FormControlLabel
                                        value="A"
                                        id = "layerA"
                                        control={<Checkbox  className={classes.detectionCheckBox} checked= {A} onChange = {handleChangeFormDataLayer} color="primary" name="A" defaultChecked/>}
                                        label={getGroupCnt.A} />
                                    <FormControlLabel
                                        value="C"
                                        control={<Checkbox className={classes.detectionCheckBox} checked= {C} onChange = {handleChangeFormDataLayer} color="primary" name="C" defaultChecked/>}
                                        label={getGroupCnt.C} />
                                    <FormControlLabel
                                        value="D"
                                        control={<Checkbox className={classes.detectionCheckBox} checked= {D} onChange = {handleChangeFormDataLayer} color="primary" name="D" defaultChecked/>}
                                        label={getGroupCnt.D} />
                                    <FormControlLabel
                                        value="B"
                                        control={<Checkbox className={classes.detectionCheckBox} checked= {B} onChange = {handleChangeFormDataLayer} color="primary" name="B" defaultChecked/>}
                                        label={getGroupCnt.B} />
                                </FormGroup>
                            </FormControl>
                        </Typography>
                    )}
                </Card>

            )}
        </ThemeProvider>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 999,
        maxWidth: 800,
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        userSelect: "none",
        backgroundColor: theme.palette.background.paper
        
    },
    detectionTitle: {
        marginBottom: 4,
        color: theme.palette.text.primary,
        fontSize: '1.25rem',
        fontWeight: 700,
        lineHeight: '1.4em',
        letterSpacing: '-0.4px'
    },
    detectionItem: {
        marginBottom: 18,
        color: theme.palette.text.primary,
        fontSize: "1rem",
        lineHeight: '1em',
        fontWeight: 500,
        letterSpacing: '-0.32px'
    },
    detectionContent: {
        alignItems: 'center',
        paddingBottom : 10        
    },
    detectionLabel: {
        marginRight : 20,
        width : '100px',
        color : theme.palette.text.primary,
        fontSize : '1rem',
        lineHeight : '1em',
        fontWeight : 700,
        whiteSpace : 'nowrap',
        flexShrink : 0,
    
    },
    detectionSelect: {
        height: '40px',
        //minWidth: '170px',
        width : '170px',
        backgroundColor: '#FFFFFF',
        backgroundImage: "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAzMCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQgNkw5IDExTDE0IDYiIHN0cm9rZT0iIzM4M0Y0MyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==')",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right center',
        backgroundSize: 'auto 18px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#DADADA',
        borderRadius: '3px',
        color: '#383F43',
        fontSize: '1rem',
        lineHeight: '38px',
        fontWeight: 700,
        letterSpacing: '-0.32px'
    },
    detectionCheck: {
        height: '40px',
        flexGrow: 1,
        '& span': {            
            color : theme.palette.text.primary,
            fontSize: '1rem'
        }
    },
    detectionCheckBox: {
        paddingRight : 5,
        fontSize: '0.25rem',
    },
}));
export default TitleInfo;
