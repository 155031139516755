import axios from 'axios';

export const findOpinions = async (projectId: string) => {
    return await axios.get(`/api/v1/opinion/${projectId}`, {
        params: {}
    });
};

export const findOpinionByDataId = async (projectId: string, dataId: number|string) => {
    return await axios.get(`/api/v1/opinion/${projectId}/${dataId}`, {
        params: {}
    });
};

export const insertOpinion = async (projectId: string, dataId: number|string, data: any) => {
    return await axios.post(`/api/v1/opinion/${projectId}/${dataId}`, data);
};

export const updateOpinion = async (projectId: string, opinionIdx: number, data: any) => {
    return await axios.put(`/api/v1/opinion/${projectId}/${opinionIdx}`, data);
};

export const deleteOpinion = async (projectId: string, opinionIdx: number) => {
    return await axios.delete(`/api/v1/opinion/${projectId}/${opinionIdx}`, {
        params: {}
    });
};
