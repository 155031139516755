import { atom } from 'recoil';
import localStorageEffect from './LocalStorage';

export const backdropState = atom<boolean>({
    key: 'backdropState',
    default: false
});

export const confirmState = atom<import('components/common/InnoConfirmDialog').InnoConfirmDialogProp>({
    key: 'confirmState',
    default: {
        open: false,
        title: '',
        content: '',
        handleCancel: ()=>{},
        handleOk: ()=>{}
    }
});

export const currentThemeState = atom<string>({
    key:"currentThemeState",
    default:"light-theme-1",
    effects_UNSTABLE: [
        localStorageEffect('currentThemeState'),
      ]
})