interface SettingsConfigInterface {
  direction: String;
  //theme: { [k: string]: string };
  themeset: {
    name: string,
    theme: {
      main: string,
      navbar: string,
      toolbar: string,
      footer: string
    }
  }[];
}
const settingsConfig: SettingsConfigInterface = {
  direction: "ltr", // rtl, ltr
  themeset: [
    {
      name: "light-theme-1",
      theme: {
        main: "light1",
        navbar: "light1",
        toolbar: "toolbar",
        footer: "light1"
      }
    },
    {
      name: "dark-theme-1",
      theme: {
        main: "dark1",
        navbar: "dark1",
        toolbar: "toolbar",
        footer: "dark1"
      }
    },
  ],
};

export default settingsConfig;
