import { atom, selector } from 'recoil';
import { selectedProjectState } from './project';
import * as layerService from '../service/LayerService';

export const isLayerSwipeState = atom<boolean>({
    key: 'isLayerSwipeState',
    default: false
});

export const isMapSplitState = atom<boolean>({
    key: 'isMapSplitState',
    default: false
});

export const isInferenceV = atom<Number>({
    key: 'isInferenceV',
    default: 0
});

export const isLayerGroupV = atom({
    key: 'isLayerGroupV',
    default:  {
        A: true,
        B: true,
        C: true,
        D: true
    }
});

export const isLayerGroupCnt = atom({
    key: 'isLayerGroupCnt',
    default:  {
        A: '신축(0)',
        B: '철거(0)',
        C: '갱신(0)',
        D: '색변화(0)'
    }
});

export const isInferenceSel = selector({
    key: 'isInferenceSel',
    get: ({ get }) => { 
      return get(isInferenceV) 
    },
    set: ({ set }, newValue) => { 
        set(isInferenceV, Number(newValue))
    }
  });

//   export const isLayerGroupSel = selector({
//     key: 'isLayerGroupSel',
//     get: ({ get }) => { 
//       return get(isLayerGroupV)
//     },
//     set: ({ set }, newValue) => { 
//         set(isLayerGroupV, ["A"] : newValue) 
//     }
//   });

const getMinMaxValue = (arr: any[], attr: string, isMax: boolean = true) => {
    return arr.reduce((p: any, c: any) => (
        (
            isMax
                ? p < c[attr] && c[attr]
                : p > c[attr] && c[attr]
        ) ? c[attr] : p
    ), arr[0][attr]);
};

export const layerListSelector = selector({
    key: 'layerListSelector',
    get: async ({ get }) => {
        const isLayerSwipe = get(isLayerSwipeState);
        const isMapSplit = get(isMapSplitState);
        const { projectId, detectionItem } = get(selectedProjectState);
        const { data } = await layerService.findLayers(projectId);
        if (detectionItem === 'BuildingChange') {
            if (isMapSplit && !isLayerSwipe) {
                return {
                    leftLayers: data.filter((e: any) =>
                        e.layerType !== 'tms' ||
                        e.year === getMinMaxValue(data, 'year', false)
                    ),
                    rightLayers: data.filter((e: any) =>
                        e.layerType !== 'tms' ||
                        e.year === getMinMaxValue(data, 'year')
                    )
                }
            } else {

                return {
                    leftLayerInfo: data.find((e: any) => e.year === getMinMaxValue(data, 'year', false)),
                    rightLayerInfo: data.find((e: any) => e.year === getMinMaxValue(data, 'year')),
                    leftLayers: data.filter((e: any) => e.layerType !== 'tms')
                }
            }

        } else {
            return { leftLayers: data }
        }
    },
});

