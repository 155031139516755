import React, { useContext, useEffect } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { makeStyles } from '@material-ui/core';
import { OpinionResultModal } from 'components/project';
import { isMapSplitState, layerListSelector } from 'states/map';
import { selectedProjectState } from 'states/project';
import MapContext from 'Map/MapContext';
import { SubLayersControl, SplitMapControl } from './index';
import { useLoadableContent } from 'hooks';
import { before } from 'lodash';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        '& #secondMap': {
            borderLeft: '1px solid rgba(0,0,0,0.8)'
        }
    },
    mapArea: {
        height: "100%",
        position: 'relative',
        float: 'left',
    },

    popAreaBackColor  : {
        backgroundColor: theme.palette.background.paper,
        " &:before": {
            borderTopColor: theme.palette.background.paper,
          }
    },

    popAreaFontColor : {
        color: theme.palette.text.primary,
    }


}));

const MapControl = ({ children }: any): React.ReactElement => {
    const classes = useStyles();
    const { innoMap } = useContext(MapContext);
    const isMapSplit = useRecoilValue(isMapSplitState);
    // const subLayersOpen = useRecoilValue(subLayersOpenSelector);
    const { projectId, year } = useRecoilValue(selectedProjectState);
    const layerList = useRecoilValueLoadable(layerListSelector);
    const { leftLayers } = useLoadableContent(layerList);

    useEffect(() => {
        if (innoMap) {
            innoMap.updateSize();
        }
    }, [innoMap, isMapSplit]);

    return (
        <div className={classes.root}>
            <div id="map" className={classes.mapArea} style={{
                width: isMapSplit ? '50%' : '100%',
            }}>
                {children}
                <SubLayersControl
                    innoMap={innoMap}
                    open={!!projectId}
                    year={year||leftLayers?.find((e: any) => e.year)?.year}
                    layers={leftLayers} />
                <OpinionResultModal/>
                
                <div id="popup" className={`${classes.popAreaBackColor} ${'ol-popup'}`}>
                    <a href="#" id="popup-closer" className={`${classes.popAreaFontColor} ${'ol-popup-closer'}`} ></a>
                    <div id="popup-content" className={classes.popAreaFontColor} ></div>
                </div>
            </div>
            {isMapSplit && (
                <SplitMapControl className={classes.mapArea} />
            )}
        </div>
    );
}

export default MapControl;