import axios from 'axios';

export const getGeoJson = async (projectId: string, layerId: number, extent: import('ol/extent').Extent) => {
    const [minx ,miny, maxx, maxy] = extent;
    return await axios.get(`/api/v1/map/project/${projectId}/layer/${layerId}`, {
        params: {
            minx,
            miny,
            maxx,
            maxy
        }
    })
};


export const getGeoJsonAll = async (projectId: string, layerId: number) => {
    return await axios.get(`/api/v1/map/project/${projectId}/layer/${layerId}`, {
        params: {
            minx : 14111401.45126364,
            miny : 4498943.0726505155,
            maxx : 14158076.028408432,
            maxy : 4537315.653058969
        }
    })
}; 

export const typeCountAll = async (projectId: string, layerId: number) => {
    return await axios.get(`/api/v1/map/project/${projectId}/layer/${layerId}/count`);
};

export const typeCount = async (projectId: string, layerId: number, inferenceData : string ) => {
    return await axios.get(`/api/v1/map/project/${projectId}/layer/${layerId}/count/${inferenceData}`);
};